// eslint-disable-next-line @nx/enforce-module-boundaries
import { Invoices } from 'swh/shared/http'

export const InvoiceStatusColors = {
  [Invoices.Status.Authorized]: 'positive',
  [Invoices.Status.Authorizing]: 'info',
  [Invoices.Status.Rejected]: 'negative',
  [Invoices.Status.Canceled]: 'neutral',
  [Invoices.Status.Canceling]: 'info',
  [Invoices.Status.Default]: 'neutral'
} as const

export const InvoiceStatusLabels: Record<Invoices.Status, string> = {
  [Invoices.Status.Authorized]: 'Autorizada',
  [Invoices.Status.Authorizing]: 'Autorizando',
  [Invoices.Status.Rejected]: 'Rejeitada',
  [Invoices.Status.Canceled]: 'Cancelada',
  [Invoices.Status.Canceling]: 'Cancelando',
  [Invoices.Status.Default]: 'Indefinida'
} as const
