import { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
// eslint-disable-next-line @nx/enforce-module-boundaries
import { UpsertMode, useCategoriesOptions, useSubCategoryOptions, useSwhConfig } from 'swh/shared/components'
import { ProductCatalog } from 'swh/shared/http'

import { type Actions } from './actions'
import { type UpsertProductState, UpsertProduct } from './upsert-product'

const noop = {
  obj: {},
  array: []
}

export const EditProduct = () => {
  const router = useRouter()
  const { store } = useSwhConfig()
  const [loading, setLoading] = useState(true)
  const categories = useCategoriesOptions(store?.id, undefined)
  const [localProduct, setLocalProduct] = useState<ProductCatalog.GetProductById | null>(null)
  const subCategories = useSubCategoryOptions(store?.id, localProduct?.category?.id)

  useEffect(() => {
    if (store?.id) {
      ProductCatalog.getById(store.id, router.query.id as string).then(p => {
        setLocalProduct(p)
        setLoading(false)
      })
    }
  }, [router.query.id, store?.id])

  const initialState = useMemo((): UpsertProductState | null => {
    if (localProduct === null) return null
    const product = localProduct
    const hasSku = product.variants.some(x => x.sku)
    const hasGtin = product.variants.some(x => x.gtin)
    const hasVariants = product.variants.every(x => Array.isArray(x.chosenVariationOptions))
    return {
      sku: hasSku,
      gtin: hasGtin,
      hasVariants,
      name: product.name,
      categoryId: product.category.id,
      description: product.description,
      subCategoryId: product.subcategory?.id || '',
      variants: product.variants.map(v => {
        const chosenVariationOptions = v.chosenVariationOptions ?? []
        const ids = chosenVariationOptions.map(x => x.option.name) ?? []
        const variationsTypes = localProduct.variationsTypes ?? []
        const label = ids.join('/')
        return {
          id: label,
          entityId: label,
          name: label,
          sku: v.sku,
          gtin: v.gtin,
          tax: { ...v.tax, gtin: v.gtin || '' },
          label: label,
          variants: [],
          variationsOptions: [],
          price: { sale: v.price.sale },
          stock: { current: v.stock.current },
          parentsId: variationsTypes.map(x => x.id),
          variationTypes: variationsTypes.map(x => x.id),
          variationsId: chosenVariationOptions.map(x => x.id)
        }
      })
    }
  }, [localProduct])

  const onSubmit = async (_: any, product: Actions.PostProduct) => {
    setLoading(true)
    if (localProduct === null) return
    try {
      // TODO: update the entire project. For alpha version, just status will be updated
      if (!store?.id) return
      await ProductCatalog.updateStatus(store?.id, localProduct.id, product.status)
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
  }

  if (localProduct === null || initialState === null) {
    return null
  }

  return (
    <UpsertProduct
      apiErrors={noop.obj}
      errors={noop.obj}
      subCategories={subCategories}
      categories={categories}
      product={localProduct}
      mode={UpsertMode.edit}
      state={initialState}
      onSubmit={onSubmit}
      loading={loading}
    />
  )
}
