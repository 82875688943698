import { useState } from 'react'
import Image from 'next/legacy/image'
import { Heading, Icon, Text } from '@dlpco/ginga-stone'
import styled from 'styled-components'

import { Flex } from '~/domains/platform/design-system'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { Choose, For } from '~/domains/platform/lib/utilities-components'

import { type Department } from '../entities'
import { useChatToken } from '../hooks/use-chat-token'
import { useCreateChannel } from '../hooks/use-create-channel'
import { useDepartments } from '../hooks/use-departments'
import { type ChatUser } from '../types'

import { CustomChatEntries } from './custom-chat-entries'

const DepartmentWrapper = styled(Flex)`
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border: 2px solid ${props => props.theme.colors.mediumGray4};
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    border: 2px solid ${props => props.theme.colors.stoneGreen};
  }

  &:active,
  &:focus {
    border: 2px solid ${props => props.theme.colors.primary};
  }
`

const DepartmentItem = ({
  iconUrl,
  label,
  onSelect,
  id
}: {
  id: number
  iconUrl: string
  label: string
  onSelect: (id: number) => void
}) => (
  <DepartmentWrapper onClick={() => onSelect(id)}>
    <Image width={22} height={22} src={iconUrl} />
    <Text weight="bold">{label}</Text>
    <Icon use="chevron-right-outline" />
  </DepartmentWrapper>
)

const SelectDepartment = ({ onSelect }: { onSelect: (department: Department) => void }) => {
  const { data: departments, isSuccess, isError } = useDepartments()

  const handleDepartmentSelect = (id: number) => {
    onSelect(departments?.find(dep => dep.id === id) as Department)
  }

  if (isSuccess) {
    return (
      <Flex px="1rem" py="1.5rem" flexDirection="column" gap="1rem">
        <Heading size="large" weight="bold">
          Como podemos te ajudar?
        </Heading>
        <For
          of={departments as Department[]}
          render={({ id, iconUrl, label }) => (
            <DepartmentItem key={id} id={id} iconUrl={iconUrl} label={label} onSelect={handleDepartmentSelect} />
          )}
        />
      </Flex>
    )
  }

  if (isError) {
    /**
     * @todo make a beauty error state :)
     */

    return null
  }

  return <Loader />
}

export const CustomLoggedChat = ({ chatUser, chatIdentity }: { chatUser: ChatUser; chatIdentity: string }) => {
  const [department, setDepartment] = useState<Department>()

  const { data: chatToken, isLoading: isTokenLoading } = useChatToken({ chatIdentity })
  const { data: channelSId, isLoading: isChannelSidLoading } = useCreateChannel({
    department,
    chatUser,
    chatIdentity
  })

  return (
    <Flex flexDirection="column" height="100%">
      <Choose>
        <Choose.When condition={Boolean(department)}>
          <Choose>
            <Choose.When condition={isChannelSidLoading || isTokenLoading}>
              <Loader />
            </Choose.When>
            <Choose.Otherwise>
              <CustomChatEntries
                chatToken={chatToken as string}
                channelSId={channelSId as string}
                chatIdentity={chatIdentity}
                department={department as Department}
                chatUser={chatUser}
              />
            </Choose.Otherwise>
          </Choose>
        </Choose.When>
        <Choose.Otherwise>
          <SelectDepartment onSelect={setDepartment} />
        </Choose.Otherwise>
      </Choose>
    </Flex>
  )
}
