import { type Operation, type OperationType, type SchedulingType, type StatementTypesFilter } from '~/lib/types/banking'

import {
  isAcquirer,
  isBlockage,
  isCashback,
  isCredit,
  isDebit,
  isDeposit,
  isInstantPayment,
  isLoanPayment,
  isPayment,
  isPayroll,
  isPixPayment,
  isPrepaidCard,
  isSalary,
  isTedTransfer,
  isTopup,
  isTransfer
} from './type-functions'

export const CANCELED_A = 'Cancelada'
export const CANCELED = 'Cancelado'

export const EXPIRED = 'Expirado'
export const FAILED = 'Falhou'
export const PAID = 'Pago'
export const PENDING = 'Em aberto'
export const PROCESSING = 'Processando'
export const RECEIVED = 'Recebido'
export const RECEIVED_A = 'Recebida'
export const REFUNDED = 'Devolvido'
export const REFUNDED_A = 'Devolvida'
export const SENT = 'Enviado'
export const SENT_A = 'Enviada'
export const BLOCKED = 'Bloqueado'
export const UNBLOCKED = 'Desbloqueado'
export const DONE = 'Feito'
export const SCHEDULED = 'Agendado'
export const SCHEDULED_A = 'Agendada'

const FINISHED_STATUS = 'FINISHED'
const CREATED_STATUS = 'CREATED'
const CANCELED_STATUS = 'CANCELED'
const REFUNDED_STATUS = 'REFUNDED'
const EXPIRED_STATUS = 'EXPIRED'
const FAILED_STATUS = 'FAILED'
const APPROVED_STATUS = 'APPROVED'
const SCHEDULED_STATUS = 'SCHEDULED'
const DELAYED_TO_NEXT_BUSINESS_DAY = 'DELAYED_TO_NEXT_BUSINESS_DAY'

export const translateStatus = (status: string, type: OperationType, operation: Operation) => {
  const translateTransferStatus = () => {
    if (status === REFUNDED_STATUS) return isDebit(operation) ? SENT_A : REFUNDED_A
    if (status === FINISHED_STATUS) {
      if (type.endsWith('refund')) return REFUNDED_A
      return isDebit(operation) ? SENT_A : RECEIVED_A
    }
    if (status === SCHEDULED_STATUS) return SCHEDULED_A
    if (status === DELAYED_TO_NEXT_BUSINESS_DAY) return SCHEDULED_A
    if (status === CANCELED_STATUS) return CANCELED_A

    return status
  }

  const translateAcquirerStatus = () => {
    if (status === FINISHED_STATUS) return RECEIVED

    return status
  }

  const translateInstantPayment = () => {
    return isDebit(operation) ? SENT : RECEIVED
  }

  const translateCashbackStatus = () => RECEIVED

  const translatePaymentStatus = () => {
    if (type === 'payment' && isCredit(operation) && status !== SCHEDULED_STATUS) return RECEIVED
    if (status === CREATED_STATUS) return PENDING
    if (status === REFUNDED_STATUS) return isDebit(operation) ? SENT : REFUNDED
    if (status === FAILED_STATUS) return FAILED
    if (status === APPROVED_STATUS) return PROCESSING
    if (status === EXPIRED_STATUS) return EXPIRED
    if (status === CANCELED_STATUS) return CANCELED
    if (status === SCHEDULED_STATUS) return SCHEDULED
    if (status === FINISHED_STATUS) {
      if (type === 'payment_refund') return REFUNDED
      return isDebit(operation) ? SENT : PAID
    }

    return status
  }

  const translateBlockage = () => {
    if (status === FINISHED_STATUS) return type === 'balance_unblocked' ? UNBLOCKED : BLOCKED
    return status
  }

  const translatePrepaidCard = () => {
    if (
      type === 'outbound_stone_prepaid_card_withdrawal_refund' ||
      type === 'outbound_stone_prepaid_card_payment_refund' ||
      type === 'outbound_stone_prepaid_card_payment_chargeback'
    ) {
      return REFUNDED_A
    }

    if (type === 'outbound_stone_prepaid_card_payment') {
      return PAID
    }

    return DONE
  }

  const translatePayroll = () => {
    return PAID
  }

  const translateSalary = () => {
    return isDebit(operation) ? PAID : RECEIVED
  }

  const translateUnknown = () => {
    return 'Não informado'
  }

  const translateLoanPayment = () => PAID

  const translatePixPayment = () => {
    if (status === SCHEDULED_STATUS) return SCHEDULED

    if (type === 'refund_for_pix_payment' || type === 'refund_reversal_for_pix_payment') {
      return isDebit(operation) ? REFUNDED : RECEIVED
    }

    if (type === 'refund_for_outbound_pix_payment') {
      return REFUNDED
    }

    return isDebit(operation) ? SENT : RECEIVED
  }

  const translateTopup = () => PAID

  if (isCashback(type)) return translateCashbackStatus()
  if (isTransfer(type)) return translateTransferStatus()
  if (isAcquirer(type)) return translateAcquirerStatus()
  if (isPayment(type)) return translatePaymentStatus()
  if (isBlockage(type)) return translateBlockage()
  if (isPrepaidCard(type)) return translatePrepaidCard()
  if (isInstantPayment(type)) return translateInstantPayment()
  if (isPayroll(type)) return translatePayroll()
  if (isSalary(type)) return translateSalary()
  if (isLoanPayment(type)) return translateLoanPayment()
  if (isPixPayment(type)) return translatePixPayment()
  if (isTopup(type)) return translateTopup()
  return translateUnknown()
}

export const typeTranslation: Record<OperationType, string> = {
  internal: 'Transferência entre contas Stone',
  external: 'TED',
  internal_refund: 'Devolução | Transferência Stone',
  external_refund: 'Devolução | TED',
  payment: 'Pagamento',
  payment_refund: 'Pagamento devolvido',
  payroll: 'Pagamento de salário',
  salary: 'Recebimento de salário',
  salary_portability: 'Portabilidade de salário',
  salary_portability_refund: 'Devolução de salário',
  salary_portability_employer_refund: 'Devolução de salário para empresa',
  salary_portability_refund_credit: 'Devolução de salário para empresa',
  card_payment: 'Recebimento | Cartão',
  balance_blocked: 'Valor Bloqueado',
  balance_unblocked: 'Valor Desbloqueado',
  outbound_stone_prepaid_card_payment: 'Pagamento com Cartão Stone',
  stone_card_invoice_payment: 'Pagamento com Cartão Stone',
  outbound_stone_prepaid_card_preauthorized_request: 'Pagamento com Cartão Stone',
  outbound_stone_prepaid_card_refund: 'Devolução | Cartão Stone',
  outbound_stone_prepaid_card_payment_chargeback: 'Devolução | Contestação',
  outbound_stone_prepaid_card_payment_refund: 'Devolução | Cartão Stone',
  outbound_stone_prepaid_card_withdrawal: 'Saque com Cartão Stone',
  outbound_stone_prepaid_card_withdrawal_refund: 'Devolução | Saque em Banco24Horas',
  outbound_stone_debit_card_withdrawal: 'Saque',
  outbound_stone_debit_card_withdrawal_refund: 'Devolução | Saque em Banco24Horas',
  outbound_stone_debit_card_payment_refund: 'Devolução | Cartão',
  cardless_withdrawal: 'Saque',
  instant_payment: 'Pagamento instantâneo',
  loan_payment: 'Parcela quitada do empréstimo',
  pix_payment: 'Pix',
  digital_content_topup: 'Pagamento de recarga',
  phone_topup: 'Pagamento de recarga',
  game_topup: 'Pagamento de recarga',
  tv_topup: 'Pagamento de recarga',
  refund_reversal_for_pix_payment: 'Pix',
  outbound_pix_payment: 'Pix',
  refund_for_outbound_pix_payment: 'Pix',
  unknown: 'Transação',
  external_transfer: 'TED',
  barcode_payment: 'Pagamento',
  barcodeless_payment: 'Pagamento de tributo',
  refund_for_barcodeless_payment: 'Devolução de Pagamento de tributo',
  cashback: 'Cashback',
  internal_transfer: 'Transferência entre contas Stone',
  barcode_payment_invoice: 'Pagamento',
  outbound_stone_debit_card_payment: 'Pagamento com Cartão Stone',
  transport_topup: 'Pagamento de recarga',
  acquirer_pix_payment_invoice: 'Pix',
  pix_payment_invoice: 'Pix',
  pix_payment_pos: 'Pix',
  investment: 'Investimentos',
  inbound_barcode_payment: 'Recebimento | Boleto',
  insurance_payment: 'Pagamento | Seguro',
  business_insurance_payment: 'Mensalidade | Seguros',
  refund_for_pix_payment: 'Devolução por Pix',
  refund_for_cardless_withdrawal: 'Pagamento',
  kickout: 'Transferência automática',
  refund_for_kickout: 'Devolução | Transferência automática',
  pix_withdrawal: 'Saque',
  direct_debit_payment: 'Débito Automático',
  refund_for_direct_debit_payment: 'Estorno de débito automático'
}

export const typeTranslationSchedule: Record<SchedulingType, string> = {
  acquirer: 'Adquirente',
  external: 'TED',
  internal: 'Transferência entre contas Stone',
  payment: 'Pagamento',
  external_transfer: 'TED',
  barcode_payment: 'Pagamento',
  internal_transfer: 'Transferência entre contas Stone',
  pix_payment: 'Transferência por Pix',
  refund_for_pix_payment: 'Devolução por Pix',
  barcodeless_payment: 'Agendamento | Pagamento de tributos e impostos sem código de barras'
}

export const detailTypeTranslation: Record<OperationType, string> = {
  internal: 'Transferência',
  external: 'Transferência',
  internal_refund: 'Transferência',
  external_refund: 'Transferência',
  payment: 'Pagamento',
  payment_refund: 'Pagamento',
  payroll: 'Folha de pagamento',
  salary: 'Recebimento de salário',
  salary_portability: 'Recebimento de Salário',
  salary_portability_refund: 'Recebimento de Salário',
  salary_portability_employer_refund: 'Recebimento de Salário',
  salary_portability_refund_credit: 'Recebimento de Salário',
  card_payment: 'Recebimento de Vendas',
  balance_blocked: 'Bloqueio',
  balance_unblocked: 'Bloqueio',
  outbound_stone_prepaid_card_preauthorized_request: 'Detalhes de compra com Cartão',
  stone_card_invoice_payment: 'Detalhes de compra com Cartão',
  outbound_stone_prepaid_card_refund: 'Detalhes de compra com Cartão',
  outbound_stone_prepaid_card_payment: 'Detalhes de compra com Cartão',
  outbound_stone_prepaid_card_payment_chargeback: 'Detalhes de compra com Cartão',
  outbound_stone_prepaid_card_payment_refund: 'Detalhes de compra com Cartão',
  outbound_stone_prepaid_card_withdrawal: 'Detalhes do saque',
  outbound_stone_prepaid_card_withdrawal_refund: 'Detalhes do saque',
  outbound_stone_debit_card_withdrawal: 'Detalhes do saque',
  outbound_stone_debit_card_withdrawal_refund: 'Detalhes do saque',
  outbound_stone_debit_card_payment_refund: 'Detalhes de compra com Cartão',
  cardless_withdrawal: 'Detalhes do saque',
  instant_payment: 'Pagamento instantâneo',
  loan_payment: 'Detalhes da parcela quitada',
  pix_payment: 'Detalhes do Pix',
  pix_payment_pos: 'Detalhes do Pix',
  digital_content_topup: 'Detalhes da recarga',
  phone_topup: 'Detalhes da recarga',
  game_topup: 'Detalhes da recarga',
  tv_topup: 'Detalhes da recarga',
  refund_for_pix_payment: 'Detalhes do Pix',
  refund_reversal_for_pix_payment: 'Detalhes do Pix',
  outbound_pix_payment: 'Detalhes do Pix',
  refund_for_outbound_pix_payment: 'Detalhes do Pix',
  barcodeless_payment: ' Pagamento de tributo',
  refund_for_barcodeless_payment: 'Detalhes do Pagamento de tributo',
  unknown: 'Transação',
  external_transfer: 'TED',
  barcode_payment: 'Pagamento',
  cashback: 'Detalhes do cashback',
  internal_transfer: 'Transferência entre contas Stone',
  barcode_payment_invoice: 'Pagamento',
  outbound_stone_debit_card_payment: 'Detalhes de compra com Cartão',
  transport_topup: 'Detalhes da recarga',
  acquirer_pix_payment_invoice: 'Detalhes do Pix',
  pix_payment_invoice: 'Detalhes do Pix',
  investment: 'Rendimentos',
  inbound_barcode_payment: 'Recebimento de boleto',
  insurance_payment: 'Pagamento de seguro',
  refund_for_cardless_withdrawal: 'Devolução',
  kickout: 'Transferência automática',
  refund_for_kickout: 'Devolução da transferência automática',
  pix_withdrawal: 'Detalhes do saque',
  business_insurance_payment: 'Mensalidade | Seguros',
  direct_debit_payment: 'Débito Automático',
  refund_for_direct_debit_payment: 'Estorno de débito automático'
}

export const translateFeesType = (type: OperationType, operation?: Operation) => {
  const translatedType = typeTranslation[type]
  const translatedFee = 'Tarifa de ' + translatedType?.toLowerCase()

  if (isDeposit(type, operation)) return 'Tarifa do boleto'
  if (isPayment(type)) return 'Tarifa de pagamento'
  if (isTedTransfer(type)) return 'Tarifa da TED'

  return translatedType ? translatedFee : 'Tarifa'
}

export const translateType = (type: OperationType, operation?: Operation) => {
  if (isDeposit(type, operation)) return 'Depósito por boleto'
  return typeTranslation[type]
}

export const translateTypeSchedule = (type: SchedulingType) => {
  return typeTranslationSchedule[type]
}

export const translateDetailType = (type: OperationType, operation?: Operation) => {
  if (isDeposit(type, operation)) return 'Detalhes do depósito'
  return detailTypeTranslation[type]
}

export const translateOperation = (operation: Operation) => (isDebit(operation) ? 'Débito' : 'Crédito')

export const getTransactionTypes = (selections?: StatementTypesFilter[]) => {
  if (!selections?.length || !selections) return []

  const transactionTypesMap: Record<StatementTypesFilter, OperationType[]> = {
    all: [],
    ted: ['external', 'external_refund'],
    pix: [
      'pix_payment',
      'refund_for_pix_payment',
      'refund_reversal_for_pix_payment',
      'outbound_pix_payment',
      'refund_for_outbound_pix_payment'
    ],
    boleto: ['payment', 'payment_refund'],
    cashback: ['cashback'],
    buyings: [
      'outbound_stone_prepaid_card_payment',
      'outbound_stone_prepaid_card_payment_refund',
      'outbound_stone_prepaid_card_payment_chargeback',
      'outbound_stone_debit_card_withdrawal',
      'outbound_stone_prepaid_card_withdrawal'
    ],
    card: ['card_payment'],
    transfer: ['internal'],
    portability: ['salary_portability', 'salary_portability_refund', 'salary_portability_employer_refund'],
    topups: ['digital_content_topup', 'game_topup', 'phone_topup', 'tv_topup', 'transport_topup'],
    investment: ['investment']
  }

  const selectedList = selections
    .map(index => {
      return transactionTypesMap[index]
    })
    .flat()

  return selectedList
}
