/* eslint-disable testing-library/no-await-sync-query */
import { useEffect, useState } from 'react'
import { type NextPage } from 'next'
import curry from 'lodash.curry'
import { getAllExperiment } from 'platform/lib/experiment'

import { Loader } from '~/domains/platform/design-system/loader/loader'
import { useDeusExMachina } from '~/domains/platform/infra/deus-ex-machina/hocs/with-deus-ex-machina'
import { type Entity, type Subject, type WithDeusExMachinaProps } from '~/domains/platform/infra/deus-ex-machina/types'
import BlackBird from '~/domains/platform/lib/blackbird'
import { CleanPageContainer } from '~/ui/containers'

enum RedirectStatus {
  Loading = 'loading',
  NotRedirect = 'notRedirect'
}

const newSalesPage = 'flag-sales-web-jade-layout'
const swhAccount = 'conta-stone-swh'

export const WithSwhRedirectComponent = ({ Page, ...props }: any) => {
  const { deusExMachina } = useDeusExMachina()
  const entity = deusExMachina?.entity as Entity<'acquirer'> | undefined
  const subject = deusExMachina?.subject as Subject | undefined
  const [redirectStatus, setRedirectStatus] = useState<RedirectStatus>(RedirectStatus.Loading)

  useEffect(() => {
    const fetchStoreConfig = async () => {
      if (!entity?.document || !subject?.id) return

      if (redirectStatus === RedirectStatus.Loading) {
        const experimentData = await getAllExperiment(subject.id, true, true, {
          swh_account_document: entity?.document
        })
        if (experimentData?.[newSalesPage]?.key === 'on' || experimentData?.[swhAccount]?.key === 'on') {
          BlackBird.travelTo(props.urlRedirect)
        } else {
          setRedirectStatus(RedirectStatus.NotRedirect)
        }
      }
    }
    fetchStoreConfig()
  }, [entity, subject?.id])

  if (redirectStatus === RedirectStatus.Loading) {
    return (
      <CleanPageContainer>
        <Loader />
      </CleanPageContainer>
    )
  }

  return <Page {...props} />
}

export const withSwhRedirectConfig = curry(function (urlRedirect: string, Page: NextPage<WithDeusExMachinaProps>) {
  return (props: any) => <WithSwhRedirectComponent {...props} Page={Page} urlRedirect={urlRedirect} />
})
