import { httpClientOpenFinance } from '../http-client-open-finance'

interface RequestType {
  accountId: string
  clientId: string
  jwe: string
}

export function auth({ jwe, accountId, clientId }: RequestType) {
  return httpClientOpenFinance({
    url: '/auth',
    method: 'POST',
    data: {
      jwe,
      accountId,
      clientId
    }
  })
}
