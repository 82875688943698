import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale/pt-BR'

const numberFormat = new Intl.NumberFormat('pt-BR', { currency: 'BRL', style: 'currency' })

export const formatToBrCurrency = (value: number, divideBy: number = 100) => {
  const newValue = value / divideBy
  return numberFormat.format(newValue)
}

export const formatToDecimal = (value: number) => value.toLocaleString('pt-BR', { minimumFractionDigits: 2 })

export const formatToBrDateTime = (dateString: string) => {
  const date = new Date(dateString)

  const dayMonthYearFormatter = new Intl.DateTimeFormat('pt-BR', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  })

  const hourMinuteFormatter = new Intl.DateTimeFormat('pt-BR', {
    hour: '2-digit',
    minute: '2-digit'
  })

  const formattedDate = dayMonthYearFormatter.format(date)
  const formattedTime = hourMinuteFormatter.format(date)

  return `${formattedDate} às ${formattedTime}`
}

export function formatDateWithoutSlash(date: Date) {
  return format(date, "dd MMM yyyy 'às' HH:mm", { locale: ptBR })
}

export const currencyToNumber = (currency: string): number => {
  const final = currency
    .replace(/,/g, '.')
    .replace(/(.*)\./, x => `${x.replace(/\./g, '')}.`)
    .replace(/[^0-9.]/g, '')
  return Number.parseFloat(final)
}
