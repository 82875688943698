import { dynamicTemplate, trackAnalyticsEvent } from '~/lib/helpers'
import { StatisticsItem } from '~/ui/components/cards/statistics-item'
import { useRequest } from '~/ui/hooks/utils/service/use-request'

export const TotalScheduledCard = ({ accountId, link }: { accountId: string; link?: boolean; large?: boolean }) => {
  const url = dynamicTemplate('/accounts/${accountId}/balance', { accountId })
  const { data }: any = useRequest<{ scheduledBalance: number }>({
    url
  })

  const scheduledBalance = data?.scheduledBalance ? Math.abs(data.scheduledBalance) : 0
  return (
    <StatisticsItem
      href={link ? '/agenda-financeira' : undefined}
      onClick={() => {
        trackAnalyticsEvent({
          action: 'click',
          category: 'Movement',
          label: 'Clicar em Saídas agendadas na tela de extrato'
        })
      }}
      moneyAmount={scheduledBalance}
      title="Saídas agendadas"
      subtitle="Próximos 12 meses"
    />
  )
}
