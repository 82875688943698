import { dynamicTemplate } from '~/lib/helpers/utils/dynamic-template'
import { type ServiceOrderOpeningRequest, type ServiceOrderOpeningResponseData } from '~/lib/types'

import { httpClientCustomer } from '..'

interface ServiceOrderOpeningParams {
  document: string
  serialNumber: string
  serviceOrderData: ServiceOrderOpeningRequest
}

export async function serviceOrderOpeningRequest({
  document,
  serialNumber,
  serviceOrderData
}: ServiceOrderOpeningParams) {
  const url = dynamicTemplate('/organizations/${document}/terminal-devices/${serialNumber}/support-service', {
    document,
    serialNumber
  })

  return await httpClientCustomer<ServiceOrderOpeningResponseData>({
    method: 'POST',
    url,
    data: serviceOrderData
  })
}
