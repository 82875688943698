import dayjs from 'dayjs'

import { type Loan } from '~/domains/credit/shared/entities'

export function isLoanFirstMonth(loan: Loan) {
  return dayjs().diff(loan.disbursementDate, 'month') === 0
}

export function applyDecimal(loanValue: number) {
  const DECIMAL = 100

  return loanValue * DECIMAL
}
