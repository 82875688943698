import { type MouseEvent, type PropsWithChildren, useState } from 'react'
import { IconButton, Link } from '@stone-payments/jade'

import BlackBird, { type Routes } from '~/domains/platform/lib/blackbird'

export type TopBannerProps = {
  text: string
  href: Routes
  variant?: 'success' | 'error'
  closable?: boolean
}

const WrapperSuccess = ({ children }: PropsWithChildren<unknown>) => (
  <div className="bg-jade-background-brand p-jade-100">{children}</div>
)

const WrapperError = ({ children }: PropsWithChildren<unknown>) => (
  <div className="bg-jade-background-negative p-jade-100">{children}</div>
)

const wrappers = {
  success: WrapperSuccess,
  error: WrapperError
} as const

export const TopBanner = ({ text, href, variant = 'success', closable = false }: TopBannerProps) => {
  const [show, setShow] = useState<boolean>(true)
  const Wrapper = wrappers[variant]

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    BlackBird.travelTo(href)
  }

  return show ? (
    <div className="w-full absolute top-20 left-0">
      <Wrapper>
        {closable && (
          <div className="flex items-center justify-center absolute inset-y-0 right-3 cursor-pointer">
            <IconButton size="small" icon="close" variant="on-color-ghost" onClick={() => setShow(false)} />
          </div>
        )}
        <div className="flex align-middle justify-center px-10 md:pl-64">
          <Link href={href} onClick={handleClick} variant="on-color" className="text-center">
            {text}
          </Link>
        </div>
      </Wrapper>
    </div>
  ) : null
}
