import { type NextApiRequest, type NextPageContext } from 'next'
import nookies, { destroyCookie } from 'nookies'
import { APP_ENV, NODE_ENV } from 'shared/envs'

export function getDomain(__appEnv__?: string) {
  const envDomain = __appEnv__ || APP_ENV()

  if (envDomain && envDomain === 'hml') {
    return 'stone.credit'
  }
  return 'conta.stone.com.br'
}

export function cookieOptions() {
  const secure = NODE_ENV() === 'production'
  return { secure }
}

export function createCookiesStorage() {
  function set(name: string, value: string, options?: any, __appEnv__?: string) {
    const { ctx, ...restOptions } = options || {}
    const domain = getDomain(__appEnv__)
    const defaultOptions = cookieOptions()

    nookies.set(ctx, name, value, { ...defaultOptions, ...restOptions })

    // Remove cookies from domain (if has domain)
    if (domain) destroyCookie(ctx, name, { domain })
  }

  function getAll(ctx?: NextPageContext | { req: NextApiRequest }) {
    return nookies.get(ctx)
  }

  function batchRemove(names: string[], ctx?: NextPageContext, __appEnv__?: string) {
    names.forEach(name => remove(name, ctx, __appEnv__))
  }

  function remove(name: string, ctx?: NextPageContext, __appEnv__?: string) {
    const domain = getDomain(__appEnv__)

    // Remove cookies from domain
    destroyCookie(ctx, name, { domain })

    // Remove any legacy cookies
    destroyCookie(ctx, name)
  }

  function removeByPrefix(prefix: string, ctx?: NextPageContext) {
    const all = getAll(ctx)

    Object.keys(all).forEach(key => {
      if (key.includes(prefix)) remove(key, ctx)
    })
  }

  return { set, removeByPrefix, batchRemove, getAll, remove }
}
