import { creditMockFlags } from '../handlers/credit'

export const mockFlagsList = [creditMockFlags]

export const mockFlagsAvailable = [...creditMockFlags.mocks] as const

export type MockFlagsAvailable = (typeof mockFlagsAvailable)[number]

export type MockFlagsStorageProps = { identifiers: string[]; mockFlags: MockFlagsAvailable[] }

export type MockFlag = {
  name: string
  scenarios: string[]
  initialScenario: string
  handlers: ScenarioMock[]
}

export type ActiveMockFlag = {
  name: string
  currentScenario: string
}

export type MockFlagMap = {
  mockFlags: ActiveMockFlag[]
  identifiers: string[]
}

export type MockProps = 'method' | 'url' | 'status' | 'delay' | 'headers' | 'body' | 'cookies'

export const AllMockProps = ['method', 'url', 'status', 'delay', 'headers', 'body', 'cookies']

export interface Mock {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  url: string | RegExp
  status: number
  delay?: number
  headers?: Record<string, string>
  body?: string
  cookies?: Record<string, string>
}

export interface ScenarioMock {
  scenario: string
  mocks: Mock[]
}

export type TempMock = {
  name: string
  tempHandler?: Mock
  checked?: boolean
  originalFlag?: string
}

export type FlagProps = MockFlag & { groupName?: string }
