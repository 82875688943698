import { type QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { Endpoints } from '../endpoints'
import { httpClientSales } from '../http-client-sales'
import { handleErrorResponse } from '../utils'

const ITEMS_PER_PAGE = 25

export namespace PaymentLayerSales {
  export type PaymentMethods = 'pix' | 'boleto' | 'card'
  export type PaymentBoletoTypes = 'bill_of_exchange' | 'proposal' | 'tuition_payment'
  export type CardTransactionType = 'credit' | 'debit' | 'voucher'
  export type OriginType = 'pos' | 'e-commerce' | 'tef'
  export type CardBrand =
    | 'Visa'
    | 'MasterCard'
    | 'AmericanExpress'
    | 'Cabal'
    | 'Ticket'
    | 'Sodexo'
    | 'VR'
    | 'Alelo'
    | 'Hipercard'
    | 'Senff'
    | 'VerdeCard'
    | 'CooperCard'
    | 'GreenCard'
    | 'UpBrasil'
    | 'VeroCard'
    | 'ValeCard'
    | 'SoroCred'
    | 'BanesCard'
    | 'BenVisaVale'
    | 'Elo'
    | 'UnionPay'
    | 'RedeCompras'

  export enum Status {
    'Authorized' = 'Authorized',
    'Approved' = 'Approved',
    'Cancelled' = 'Cancelled',
    'TransactionFail' = 'TransactionFail',
    'Dispute' = 'Dispute'
    // "Captured" = "Captured",
    // "PreAuthorization" = "PreAuthorization"
  }

  export type Origin = {
    serialNumber: string
    type: OriginType
  }

  export type Item = {
    id: string
    createdAt: string
    paymentMethod: PaymentMethods
    cardTransactionType: CardTransactionType
    installments: number | null
    cardBrand: CardBrand | null
    status: Status
    origin: Origin
    amount: 100000
  }

  export type Response = {
    items: Item[]
    size?: 1
    current?: 1
    next?: number
    total?: 3
  }

  export interface BaseBucket {
    name: PaymentMethods | CardTransactionType | PaymentBoletoTypes
    id: string | null
    quantity: number
    amount: number
    net_amount: number
    updated_amount: number
    avg_updated_amount: number | null
    avg_amount: number | null
    canceled_amount: number
    chargeback_amount: number
    chargeback_refund_amount: number
    fee_amount: number
    buckets: Record<string, any>
  }

  export interface CardBrandBucket extends BaseBucket {}

  export interface CardType extends BaseBucket {
    buckets: {
      card_brand: CardBrandBucket[]
    }
  }

  export interface BucketsTypes extends BaseBucket {
    buckets: {
      card_type: CardType[]
      pix_type: BaseBucket[]
      boleto_type: BaseBucket[]
    }
  }

  export type TypesBuckets = {
    pix_type?: BaseBucket[]
    boleto_type?: BaseBucket[]
    card_type?: CardType[]
  }

  export interface Buckets {
    card: BucketsTypes[]
    pix: BucketsTypes[]
    boleto: BucketsTypes[]
  }

  export interface ResumePaymentsDataLayer extends BaseBucket {
    buckets: Buckets
  }

  export interface ResponseResumePaymentsDataLayer extends BaseBucket {
    data: ResumePaymentsDataLayer
  }

  type FilterContent = {
    value: string | number
  }

  export type Filter = {
    selectedPeriodOption: { initialDate: Date; endDate: Date }
    selectedCaptureMethodsOptions: FilterContent[]
    selectedPaymentsOptions: FilterContent[]
    installments: number | null
    search: string
    deviceNumber: string
    brands: FilterContent[]
    prices: { min: number; max: number }
  }
  export const getAll = async (
    cnpj: string | undefined,
    next: number,
    filter: Filter
  ): Promise<Response | undefined> => {
    if (cnpj === undefined) return undefined
    const endpoint = Endpoints.sales.getAllPaymentLayerSales(cnpj)
    const pricesFilter =
      filter.prices.min <= filter.prices.max && filter.prices.max > 0
        ? { MinAmount: filter.prices.min, MaxAmount: filter.prices.max }
        : {}

    try {
      const response = await httpClientSales.get<Response>(endpoint, {
        params: {
          pageSize: ITEMS_PER_PAGE,
          next: next,
          captureMethods: (filter.selectedCaptureMethodsOptions ?? []).map(el => el.value).join(','),
          paymentMethods: (filter.selectedPaymentsOptions ?? []).map(el => el.value).join(','),
          brands: (filter.brands ?? []).map(el => el.value).join(','),
          start: filter?.selectedPeriodOption?.initialDate?.toISOString() ?? '',
          search: filter.search ?? '',
          deviceNumber: filter.deviceNumber ?? '',
          installments: !filter.installments ? '' : filter.installments,
          end: filter?.selectedPeriodOption?.endDate?.toISOString() ?? '',
          ...pricesFilter
        }
      })
      return response.data
    } catch (error: unknown) {
      handleErrorResponse(error)
    }
  }

  export const getResume = async (
    document: string | undefined,
    filter: Filter
  ): Promise<ResponseResumePaymentsDataLayer | undefined> => {
    if (document === undefined) return undefined
    const endpoint = Endpoints.sales.getPaymentLayerSalesResume(document)
    try {
      const response = await httpClientSales.get<ResponseResumePaymentsDataLayer>(endpoint, {
        params: {
          pageSize: ITEMS_PER_PAGE,
          captureMethods: filter.selectedCaptureMethodsOptions.map(el => el.value).join(','),
          paymentMethods: filter.selectedPaymentsOptions.map(el => el.value).join(','),
          start: filter.selectedPeriodOption.initialDate.toISOString(),
          end: filter.selectedPeriodOption.endDate.toISOString()
        }
      })
      return response.data
    } catch (error: unknown) {
      handleErrorResponse(error)
    }
  }
}

export namespace PaymentLayerSaleDetail {
  export type SalesProductsItem = {
    id: string
    name: string
    image: string
    price: number
    quantity: string
  }

  export type Response = {
    amount: number
    updatedAmount: number
    status: string
    paymentMethod: string
    createdAt: string
    merchant: Merchant
    boletoTransaction: BoletoTransaction | null
    cardTransaction: CardTransaction | null
    pixTransaction: PixTransaction | null
    netAmount: number
    items: SalesProductsItem[]
  }

  export type Merchant = {
    organization: string
    document: string
    userId: string
    affiliationKey: string
  }

  export type BoletoTransaction = {
    type: 'bill_of_exchange' | 'proposal'
    transactionId: string
    ourNumber: string
    fineAmount: number
    interestAmount: number
    feeAmount: number
    expireAt: string
    writableLine: string
    bank: Bank
    fine: Fine
  }

  export type Bank = {
    name: string
  }

  export type Fine = {
    value: string
  }

  export type CardTransaction = {
    type: string
    transactionId: string
    capturedAmount: number
    canceledAmount: number
    chargebackAmount: number
    chargebackRefundAmount: number
    authorizationCode: string
    installments: number
    card: Card
    details: Detail[]
    feeAmount: number
    mdrAmount: number
    mdrRefundAmount: number
    ravAmount: number
    ravRefundAmount: number
    terminal: Terminal
  }

  export type Card = {
    brand: string
    firstSixDigits: string
    lastFourDigits: string
  }

  export type Detail = {
    operation: string
    providerDatetime: string
    operationReason: string
  }

  export type Terminal = {
    type: string
    serialNumber: string
  }

  export type PixTransaction = {
    transactionId: string
    e2eId: string
    feeAmount: number
    payer: Payer
    terminal: Terminal
  }

  export type Payer = {
    name: string
    document: string
    institutionName: string
  }

  const getSale = async (ctx: QueryFunctionContext): Promise<Response> => {
    const [, cnpj, saleId] = ctx.queryKey
    try {
      const response = await httpClientSales.get<Response>(
        Endpoints.sales.getPaymentLayerSaleById(cnpj as string, saleId as string)
      )
      return response.data
    } catch (error: unknown) {
      return handleErrorResponse(error)
    }
  }
  export function useFetchSalesById(cnpj: string | null | undefined, saleId: string | undefined) {
    return useQuery<Response, unknown>({
      queryKey: ['sales-by-id', cnpj, saleId],
      queryFn: getSale,
      refetchOnWindowFocus: false,
      enabled: !!cnpj && !!saleId
    })
  }
}
