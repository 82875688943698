import { type PropsWithChildren, useState } from 'react'

import { useMerchants } from '~/domains/acquirer/ports/hooks/use-merchants'
import { useTerminalDevices } from '~/domains/acquirer/ports/hooks/use-terminal-devices'
import { useStoneBrandFeesSimulator } from '~/domains/acquirer/sale-simulator/hooks'
import { useStoneBrands } from '~/domains/miscellaneous/ports/hooks/use-stone-brands'
import { Dimmer } from '~/domains/platform/design-system/dimmer'
import { ExclamationIllustration } from '~/domains/platform/design-system/illustrations/exclamation.illustration'
import { Loader } from '~/domains/platform/design-system/loader/loader'
import { type Entity } from '~/domains/platform/infra/deus-ex-machina/types'
import { Choose } from '~/domains/platform/lib/utilities-components'
import { type PurchaseStoneCode, type PurchaseStoneCodeBrand } from '~/lib/types'
import { ContentModal } from '~/ui/components/modals/content-modal/content-modal'
import { EmptyState } from '~/ui/components/utils/empty-state/empty-state'

import { StonecodeSelection } from './partials/stonecode-selection'
import { Content } from './content'
type BrandType = { cardBrandId: number; transactionProfileId: number }

interface SaleSimulatorProps {
  isOpen: boolean
  toggle: () => void
  entity: Entity<'acquirer'>
}

export function SaleSimulator({ isOpen, toggle, entity }: PropsWithChildren<SaleSimulatorProps>) {
  const { stoneCodes } = entity
  const [selectedStonecode, setSelectedStonecode] = useState<PurchaseStoneCode | undefined>()
  const {
    error: terminalDeviceError,
    terminalDevices,
    isSuccess: isTerminalDeviceSuccess
  } = useTerminalDevices(entity.document)
  const { data: brandFees = [], isSuccess: isFeesSuccess } = useStoneBrandFeesSimulator(entity.stoneCodes[0]?.stoneCode)
  const {
    merchants,
    isSuccess: isMerchantsSuccess,
    error: merchantsError
  } = useMerchants(entity.stoneCodes.map(item => item.stoneCode))

  const { brands = [], isSuccess: isStoneBrandsSuccess } = useStoneBrands(stoneCodes[0]?.stoneCode)

  const handleSelectStonecode = (selected: PurchaseStoneCode | undefined) => {
    setSelectedStonecode(selected)
  }

  function labelsReducer(acc: any, curr: any) {
    const brand = brands?.find((brand: PurchaseStoneCodeBrand) => brand.brandId === curr[0].cardBrandId)
    const label = {
      cardBrandId: curr[0].cardBrandId,
      brandName: brand && (brand as PurchaseStoneCodeBrand).brandName,
      product: curr.map(({ transactionProfileId }: BrandType) => (transactionProfileId === 1 ? 'credit' : 'debit'))
    }
    return [...acc, label]
  }

  const labels = brandFees.reduce(labelsReducer, [])
  const ready = isFeesSuccess && isStoneBrandsSuccess && isTerminalDeviceSuccess && isMerchantsSuccess

  return (
    <ContentModal isOpen={isOpen} onClose={toggle} title="Simulador de Vendas" headerPosition="left">
      {ready ? (
        <Choose>
          <Choose.When condition={Boolean(selectedStonecode)}>
            <Content
              terminalDevices={terminalDevices}
              handleSelect={handleSelectStonecode}
              labels={labels}
              toggle={toggle}
              currentStoneCode={selectedStonecode as PurchaseStoneCode}
              currentAccount={entity}
              merchants={merchants}
            />
          </Choose.When>
          <Choose.Otherwise>
            <StonecodeSelection
              terminalDevices={terminalDevices}
              stoneCodes={stoneCodes}
              handleSelect={handleSelectStonecode}
              merchants={merchants}
            />
          </Choose.Otherwise>
        </Choose>
      ) : (
        <Choose>
          <Choose.When condition={Boolean(terminalDeviceError || merchantsError)}>
            <EmptyState illustration={<ExclamationIllustration />} title="Serviço temporariamente indisponível" />
          </Choose.When>
          <Choose.Otherwise>
            <Dimmer isVisible>
              <Loader />
            </Dimmer>
          </Choose.Otherwise>
        </Choose>
      )}
    </ContentModal>
  )
}
