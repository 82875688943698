import { type Operation, type OperationType, type PaymentLinkCharge, type UIMovementType } from '~/lib/types'

const includesAny =
  <T = OperationType>(types: T[]) =>
  (type: T) =>
    types.includes(type)

export const isTransfer = includesAny([
  'internal',
  'internal_refund',
  'external',
  'external_refund',
  'external_transfer',
  'internal_transfer'
])

export const isTedTransfer = includesAny(['external_transfer', 'external_refund', 'external'])

export const isPayment = includesAny(['payment', 'payment_refund', 'barcode_payment', 'direct_debit_payment'])
export const isPayroll = includesAny(['payroll'])
export const isSalary = includesAny(['salary'])
export const isLoanPayment = includesAny(['loan_payment'])
export const isPixPayment = includesAny([
  'pix_payment',
  'refund_for_pix_payment',
  'refund_reversal_for_pix_payment',
  'outbound_pix_payment',
  'refund_for_outbound_pix_payment'
])

export const isWithdrawal = includesAny([
  'outbound_stone_prepaid_card_withdrawal_refund',
  'outbound_stone_debit_card_withdrawal_refund',
  'outbound_stone_prepaid_card_withdrawal',
  'outbound_stone_debit_card_withdrawal',
  'refund_for_cardless_withdrawal',
  'cardless_withdrawal',
  'pix_withdrawal'
])

export const isStatement = includesAny(['statement', 'direct_debit_payment'])

export const isTopup = includesAny([
  'phone_topup',
  'game_topup',
  'transport_topup',
  'digital_content_topup',
  'tv_topup'
])

export const isShareablePixPayment = includesAny([
  'pix_payment',
  'outbound_pix_payment',
  'refund_for_pix_payment',
  'refund_for_outbound_pix_payment',
  'refund_reversal_for_pix_payment'
])

export const isBarcodelessPayment = includesAny(['barcodeless_payment'])

export const isCashback = includesAny(['cashback'])

export const isDeposit = (type: OperationType, operation?: Operation) => type === 'payment' && operation === 'credit'

export const isPrepaidCard = includesAny([
  'outbound_stone_prepaid_card_payment',
  'outbound_stone_prepaid_card_payment_chargeback',
  'outbound_stone_prepaid_card_payment_refund',
  'outbound_stone_prepaid_card_withdrawal',
  'outbound_stone_prepaid_card_withdrawal_refund'
])

export const isRefund = includesAny([
  'internal_refund',
  'external_refund',
  'payment_refund',
  'salary_portability_refund',
  'outbound_stone_prepaid_card_payment_chargeback',
  'outbound_stone_prepaid_card_payment_refund',
  'outbound_stone_prepaid_card_withdrawal_refund',
  'refund_for_outbound_pix_payment'
])

export const isBlocked = (type: OperationType) => type === 'balance_blocked'
export const isUnBlocked = (type: OperationType) => type === 'balance_unblocked'

export const isAcquirer = includesAny(['card_payment'])
export const isInstantPayment = includesAny(['instant_payment'])

export const isBlockage = includesAny(['balance_blocked', 'balance_unblocked'])

export const isDebit = (operation: Operation) => operation === 'debit'
export const isCredit = (operation: Operation) => operation === 'credit'

export const getUIMovementType = (type: OperationType, operation: Operation) => {
  const typeUIMap: Record<OperationType, UIMovementType> = {
    salary: 'company',
    salary_portability_refund_credit: 'company',
    salary_portability: 'bank',
    salary_portability_refund: 'bank',
    payroll: 'payroll',
    salary_portability_employer_refund: 'worker',
    internal: 'transfer',
    external: 'transfer',
    internal_refund: 'transfer',
    external_refund: 'transfer',
    payment: 'payment',
    payment_refund: 'payment',
    outbound_stone_prepaid_card_payment: 'prepaidCard',
    outbound_stone_prepaid_card_payment_chargeback: 'prepaidCard',
    outbound_stone_prepaid_card_payment_refund: 'prepaidCard',
    stone_card_invoice_payment: 'debitCard',
    outbound_stone_prepaid_card_refund: 'prepaidCard',
    outbound_stone_prepaid_card_preauthorized_request: 'prepaidCard',
    outbound_stone_prepaid_card_withdrawal: 'prepaidCard',
    outbound_stone_prepaid_card_withdrawal_refund: 'prepaidCard',
    outbound_stone_debit_card_withdrawal: 'debitCard',
    outbound_stone_debit_card_withdrawal_refund: 'debitCard',
    outbound_stone_debit_card_payment_refund: 'debitCard',
    cardless_withdrawal: 'prepaidCard',
    balance_blocked: 'balance',
    balance_unblocked: 'balance',
    card_payment: 'acquirer',
    instant_payment: 'instantPayment',
    loan_payment: 'loanPayment',
    phone_topup: 'phoneTopup',
    game_topup: 'gameTopup',
    digital_content_topup: 'digitalContentTopup',
    tv_topup: 'tvTopup',
    pix_payment: 'pixPayment',
    refund_for_pix_payment: 'pixPayment',
    pix_payment_pos: 'pixPayment',
    refund_reversal_for_pix_payment: 'pixPayment',
    outbound_pix_payment: 'pixPayment',
    refund_for_outbound_pix_payment: 'pixPayment',
    unknown: 'unknown',
    external_transfer: 'transfer',
    barcode_payment: 'payment',
    barcodeless_payment: 'barcodelessPayment',
    refund_for_barcodeless_payment: 'barcodelessPayment',
    cashback: 'unknown',
    internal_transfer: 'transfer',
    barcode_payment_invoice: 'payment',
    outbound_stone_debit_card_payment: 'debitCard',
    transport_topup: 'transportTopup',
    acquirer_pix_payment_invoice: 'transfer',
    pix_payment_invoice: 'pixPayment',
    investment: 'investment',
    inbound_barcode_payment: 'payment',
    insurance_payment: 'payment',
    business_insurance_payment: 'payment',
    refund_for_cardless_withdrawal: 'payment',
    kickout: 'payment',
    refund_for_kickout: 'payment',
    pix_withdrawal: 'pixPayment',
    direct_debit_payment: 'directDebitPayment',
    refund_for_direct_debit_payment: 'directDebitPayment'
  }

  if (isDeposit(type, operation)) return 'deposit'

  return typeUIMap[type] ?? 'unknown'
}

export const findPaidCharge = (charges: PaymentLinkCharge[] = []) =>
  charges.filter(charge => charge.status === 'paid')[0]
