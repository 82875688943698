import { Endpoints } from '../endpoints'
import { httpClient } from '../http-client'

import { type SwhResponse } from './types'

export namespace Variants {
  export enum ProductStatus {
    All = 'all',
    Draft = 'draft',
    Published = 'published'
  }

  export type VariationOption = {
    id: string
    name: string
  }

  export type VariationType = {
    id: string
    name: string
    variationOptions: VariationOption[]
  }

  export const get = async (storeId: string): Promise<VariationType[]> => {
    try {
      const response = await httpClient.get<SwhResponse<VariationType>>(Endpoints.variations(storeId))
      return response.data.items
    } catch (e) {
      console.error(e)
      return []
    }
  }

  export const post = async (
    storeId: string,
    toUpdate: VariationType,
    lastUpdates: VariationOption[]
  ): Promise<VariationType> => {
    const id = toUpdate.id
    const result = toUpdate.variationOptions.reduce(
      (acc, update) => {
        if (update.name === '') return acc
        const previous = lastUpdates.find(p => p.id === update.id)
        if (previous) return { exists: acc.exists.concat(previous), update: acc.update }
        return { update: acc.update.concat([{ name: update.name } as unknown as VariationOption]), exists: acc.exists }
      },
      { exists: [] as VariationOption[], update: [] as VariationOption[] }
    )
    if (result.update.length === 0) {
      return { id, name: toUpdate.name, variationOptions: result.exists }
    }
    const response = await httpClient.post<SwhResponse<VariationType>>(Endpoints.variationOptions(storeId, id), {
      options: result.update
    })
    return { id, name: toUpdate.name, variationOptions: response.data.items }
  }
}
