import { type NextPage } from 'next'
import { Stack } from '@dlpco/fluid-layout'
import curry from 'lodash.curry'

import { type WithDeusExMachinaProps } from '../../deus-ex-machina/ports'

import { Navigation } from './containers/navigation'
import { SettingsNavigation } from './containers/settings-navigation'
import { TopBarOnlyContainer } from './containers/top-bar-only-container'

export type NavigationType = 'default' | 'settings' | 'none' | 'top-bar-only' | 'fullscreen' | 'settings-fullscreen'

export const withNavigation = curry(function withNavigation(
  type: NavigationType,
  Page: NextPage<WithDeusExMachinaProps>
) {
  function WrappedPage({ subject, entity, ...rest }: WithDeusExMachinaProps) {
    if (type === 'settings')
      return (
        <SettingsNavigation entity={entity} subject={subject}>
          <Page subject={subject} entity={entity} {...rest} />
        </SettingsNavigation>
      )

    if (type === 'settings-fullscreen')
      return (
        <SettingsNavigation entity={entity} subject={subject} fullscreen={true}>
          <Page subject={subject} entity={entity} {...rest} />
        </SettingsNavigation>
      )

    if (type === 'fullscreen')
      return (
        <Navigation entity={entity} subject={subject} fullscreen={type === 'fullscreen'}>
          <Page subject={subject} entity={entity} {...rest} />
        </Navigation>
      )

    if (type === 'default')
      return (
        <Navigation entity={entity} subject={subject}>
          <Stack space="2rem">
            <Page subject={subject} entity={entity} {...rest} />
          </Stack>
        </Navigation>
      )

    if (type === 'top-bar-only')
      return (
        <TopBarOnlyContainer subject={subject}>
          <Page subject={subject} entity={entity} {...rest} />
        </TopBarOnlyContainer>
      )

    return <Page subject={subject} entity={entity} {...rest} />
  }

  WrappedPage.displayName = `withNavigation(${Page.displayName})`

  return WrappedPage
})
