import { forwardRef } from 'react'
import css from '@styled-system/css'
import type * as StyledComponents from 'styled-components'
import styled from 'styled-components'
import {
  background,
  border,
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography
} from 'styled-system'

import { theme } from '~/ui/theme'

import {
  type BoxProps,
  type CSSProps,
  type InputProps,
  type LinkProps,
  type SelectProps,
  type TextareaProps,
  type TextProps
} from './types'

const sx = (props: CSSProps) => css(props.sx)
const style = (props: CSSProps) => css(props.style)

export const Box = styled.div<BoxProps>(
  sx,
  style,
  compose(background, border, color, compose, flexbox, grid, layout, position, shadow, space, typography)
)

export const Flex = styled(Box)<{ gap?: StyledComponents.CSSProperties['gap'] }>`
  display: flex;
  gap: ${props => props?.gap};
`

/**
 * @deprecated Ginga Components should be used instead:
 * @see [Card](https://ginga.docs.stone.credit/?path=/story/container-card--elemento)
 **/
export const Card = styled(Box)<{ bordered?: boolean; hidden?: boolean }>`
  background-color: ${theme.variants.card.bg};
  padding: 1.5rem;
  box-sizing: border-box;
  box-shadow: ${theme.shadows.card};
  border: ${props => props.bordered && `1px solid ${theme.colors.lightGray}`};
  border-radius: 1rem;
  overflow: ${props => props.hidden && `hidden`};
`

export const Link: React.FunctionComponent<React.PropsWithChildren<LinkProps>> = forwardRef(
  ({ children, style, ...props }, _ref) => (
    <Box
      as="a"
      ref={_ref}
      {...props}
      style={{ textDecoration: 'none', cursor: 'pointer', color: `${theme.variants.link.color}`, ...style }}
    >
      {children}
    </Box>
  )
)

/**
 * @deprecated Ginga Components should be used instead:
 * @see [Text](https://ginga.docs.stone.credit/?path=/story/tipografia-text--elemento)
 **/
export const Text: React.FunctionComponent<React.PropsWithChildren<TextProps>> = props => <Box as="p" {...props} />

export const Input: React.FunctionComponent<React.PropsWithChildren<InputProps>> = forwardRef((props, ref) => (
  <Box
    ref={ref}
    as="input"
    type="text"
    tx="forms"
    variant="input"
    {...props}
    style={{
      display: 'block',
      width: '100%',
      p: 2,
      appearance: 'none',
      lineHeight: 'inherit',
      borderWidth: '1px',
      borderRadius: 'default',
      color: 'inherit',
      bg: 'transparent'
    }}
  />
))

const SVG = ({ size = 24, ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="currentcolor"
    {...props}
  />
)

const DownArrow = (props: CSSProps) => (
  <SVG {...props}>
    <path d="M7 10l5 5 5-5z" />
  </SVG>
)

export const Select: React.FunctionComponent<React.PropsWithChildren<SelectProps>> = forwardRef((props, ref) => (
  <Flex>
    <Box
      ref={ref}
      as="select"
      tx="forms"
      variant="select"
      style={{
        display: 'block',
        width: '100%',
        p: 2,
        appearance: 'none',
        lineHeight: 'inherit',
        border: `1px solid ${theme.colors.border.normal}`,
        borderRadius: 'default',
        color: 'inherit',
        bg: 'transparent'
      }}
      {...props}
    />
    <DownArrow
      sx={{
        ml: -28,
        alignSelf: 'center',
        pointerEvents: 'none',
        bg: 'white',
        padding: '1px'
      }}
    />
  </Flex>
))

export const Textarea: React.FunctionComponent<React.PropsWithChildren<TextareaProps>> = forwardRef((props, ref) => (
  <Box
    ref={ref}
    as="textarea"
    tx="forms"
    variant="textarea"
    style={{
      display: 'block',
      width: '100%',
      p: 2,
      appearance: 'none',
      lineHeight: 'inherit',
      border: `1px solid ${theme.colors.border.normal}`,
      borderRadius: 'default',
      color: 'inherit',
      bg: 'transparent'
    }}
    {...props}
  />
))
