export const INVESTMENT_ROUTES = [
  '/rendimentos',
  '/rendimentos/sobre-seus-rendimentos',
  '/rendimentos/objetivos/${id}',
  '/rendimentos/objetivos/${id}/editar',
  '/rendimentos/objetivos/organize-suas-metas',
  '/rendimentos/objetivos/criar-objetivo/selecione-uma-categoria',
  '/rendimentos/objetivos/criar-objetivo/configure-seu-objetivo',
  '/rendimentos/objetivos/criar-objetivo/revise-seu-objetivo',
  '/rendimentos/objetivos/${id}/guardar-dinheiro/como-voce-quer-guardar',
  '/rendimentos/objetivos/${id}/guardar-dinheiro/quanto-voce-quer-guardar-pra-render',
  '/rendimentos/objetivos/${id}/guardar-dinheiro/informe-seu-pin',
  '/rendimentos/objetivos/selecione-um-objetivo-para-guardar',
  '/rendimentos/objetivos/${id}/guardar-dinheiro/automaticamente/escolha-uma-forma-de-guardar-automaticamente',
  '/rendimentos/objetivos/${id}/guardar-dinheiro/automaticamente/valor-fixo/defina-suas-regras',
  '/rendimentos/objetivos/${id}/guardar-dinheiro/automaticamente/valor-fixo/revise-suas-regras',
  '/rendimentos/objetivos/${id}/guardar-dinheiro/automaticamente/valor-fixo/informe-seu-pin',
  '/rendimentos/objetivos/selecione-um-objetivo-para-resgatar',
  '/rendimentos/objetivos/${id}/resgatar-dinheiro',
  '/rendimentos/objetivos/${id}/resgatar-dinheiro/informe-seu-pin',
  '/rendimentos/objetivos/${id}/investimentos-programados',
  '/rendimentos/objetivos/${id}/guardar-dinheiro/automaticamente/porcentagem/defina-suas-regras',
  '/rendimentos/objetivos/${id}/guardar-dinheiro/automaticamente/porcentagem/revise-suas-regras',
  '/rendimentos/objetivos/${id}/guardar-dinheiro/automaticamente/porcentagem/informe-seu-pin',
  '/rendimentos/objetivos/${id}/investimentos-programados/${stid}/alterar/altere-suas-regras',
  '/rendimentos/objetivos/${id}/investimentos-programados/${stid}/alterar/revise-suas-regras',

  // scfi v2
  '/rendimentos/v2',
  '/rendimentos/v2/objetivos/${bid}',
  '/rendimentos/v2/objetivos/${bid}/produtos',
  '/rendimentos/v2/objetivos/${bid}/produtos/${pid}',
  '/rendimentos/v2/objetivos/${bid}/alterar',
  '/rendimentos/v2/objetivos/${bid}/extrato',
  '/rendimentos/v2/objetivos/criar',
  '/rendimentos/v2/quer-guardar-dinheiro',
  '/rendimentos/v2/sem-ofertas-disponiveis',
  '/rendimentos/v2/como-quer-guardar',
  '/rendimentos/v2/guardar-dinheiro/por-valor',
  '/rendimentos/v2/guardar-dinheiro/por-valor/confira-os-dados',
  '/rendimentos/v2/guardar-dinheiro/por-valor/informe-seu-pin',
  '/rendimentos/v2/guardar-dinheiro/selecione-um-objetivo',
  '/rendimentos/v2/guardar-dinheiro/por-recebimentos',
  '/rendimentos/v2/guardar-dinheiro/por-recebimentos/confira-os-dados',
  '/rendimentos/v2/guardar-dinheiro/por-recebimentos/informe-seu-pin',
  '/rendimentos/v2/produtos/alterar-produto',
  '/rendimentos/v2/produtos/${pid}',
  '/rendimentos/v2/informacoes/fgc',
  '/rendimentos/v2/informacoes/rentabilidade',
  '/rendimentos/v2/informacoes/condicoes-de-resgate',
  '/rendimentos/v2/informacoes/tributacao',
  '/rendimentos/v2/resgatar-dinheiro',
  '/rendimentos/v2/resgatar-dinheiro/selecione-um-produto',
  '/rendimentos/v2/resgatar-dinheiro/selecione-um-objetivo',
  '/rendimentos/v2/resgatar-dinheiro/informe-seu-pin',
  '/rendimentos/v2/regras',
  '/rendimentos/v2/regras/alterar/por-valor',
  '/rendimentos/v2/regras/alterar/por-recebimentos'
] as const
