import { type BadgeComponent, type OwnPropsOf } from '@dlpco/ginga-stone'

import { type PrepayAgreementFinancialPosition } from '~/domains/acquirer/rav/shared/entities/financial-position'
import { type PrepayAgreement } from '~/domains/acquirer/rav/shared/entities/prepay-agreement'
import {
  type DetailedPrepayCondition,
  type PrepayFrequencyDaysMonthly,
  type PrepayFrequencyDaysWeekly,
  type PrepayFrequencyType,
  type PrepayStatus
} from '~/domains/acquirer/rav/shared/entities/prepay-condition'

import { stringFormat } from '../../utils/string-format'
import { toSentence } from '../../utils/to-sentence'

export const transcribeFrequencyType: Record<PrepayFrequencyType, string[]> = {
  Daily: ['Diariamente', 'Diária', 'Você ativou a antecipação diária automática e vai receber todo dia útil.'],
  Weekly: [
    'Semanalmente',
    'Semanal',
    'Você ativou a antecipação semanal automática e vai receber nos dias escolhidos.'
  ],
  Monthly: ['Mensalmente', 'Mensal', 'Você ativou a antecipação mensal automática e vai receber nos dias escolhidos.']
}

export const transcribeWeeklyFrequency: Record<PrepayFrequencyDaysWeekly, string> = {
  1: 'Segunda',
  2: 'Terça',
  3: 'Quarta',
  4: 'Quinta',
  5: 'Sexta'
}

export function transcribeFrequencyDays(
  frequencyType: PrepayFrequencyType,
  frequencyDays?: PrepayFrequencyDaysWeekly[] | PrepayFrequencyDaysMonthly[] | null,
  hasSettlementEveryDayOn?: boolean
) {
  if (hasSettlementEveryDayOn) {
    return 'Todo os dias, incluindo finais de semana e feriados'
  }

  if (frequencyType === 'Daily') {
    return 'Todo dia útil'
  }

  if (frequencyType === 'Weekly' && frequencyDays?.length) {
    return stringFormat.capitalizeFirstLetter(
      toSentence(
        (frequencyDays as PrepayFrequencyDaysWeekly[]).map(weekDay => transcribeWeeklyFrequency[weekDay]?.toLowerCase())
      )
    )
  }

  if (frequencyType === 'Monthly' && frequencyDays?.length) {
    return toSentence(
      (frequencyDays as PrepayFrequencyDaysMonthly[]).sort((a, b) => a - b).map(day => `0${day}`.slice(-2))
    )
  }
}

export const translateFrequencyType: Record<string, string> = {
  Daily: 'Diária',
  Weekly: 'Semanal',
  Monthly: 'Mensal'
}

export const transcribePreposition: Record<string, string> = {
  Daily: '',
  Weekly: 'toda',
  Monthly: 'todo dia'
}

export const agreementStatusColorMap: Record<PrepayStatus, OwnPropsOf<BadgeComponent>['color']> = {
  Created: 'neutral',
  Approved: 'info',
  Registered: 'info',
  PaymentRequested: 'positive',
  Cancelled: 'negative',
  CancellationRequested: 'negative',
  AnalysisRequested: 'info'
}

export const agreementStatusMap: Record<PrepayStatus, string> = {
  Created: 'Simulado',
  Approved: 'Solicitada',
  Registered: 'Solicitada',
  PaymentRequested: 'Aprovada',
  Cancelled: 'Cancelada',
  CancellationRequested: 'Cancelada',
  AnalysisRequested: 'Em Análise'
}

export function buildBadgeText(
  type: string,
  prepayCondition?: DetailedPrepayCondition,
  automaticAgreement?: PrepayAgreement
) {
  const frequencyType = automaticAgreement?.frequencyType as PrepayFrequencyType

  const frequencyForAutomatic = translateFrequencyType[frequencyType]
  const automaticFee = prepayCondition?.serviceFees.automatic
  const spotFee = prepayCondition?.serviceFees.spot

  if (type === 'spot') {
    return spotFee
  } else if (type === 'automatic' && Boolean(automaticAgreement)) {
    return `${frequencyForAutomatic} ativa - ${automaticFee}`
  } else {
    return automaticFee
  }
}

interface UnavailableBalanceType {
  status: 'V1' | 'V2'
  value: number | undefined
}

export function unavailableBalanceByCustomerVersion(
  prepayCondition?: DetailedPrepayCondition,
  financialPosition?: PrepayAgreementFinancialPosition
): UnavailableBalanceType | undefined {
  const hasValueForV1Customer =
    prepayCondition?.customerVersion == 'V1' &&
    !financialPosition?.warrantyAmount &&
    Number(financialPosition?.warrantyRate) > 0

  const hasValueForV2Customer =
    prepayCondition?.customerVersion == 'V2' &&
    Number(financialPosition?.warrantyAmount) > 0 &&
    !financialPosition?.warrantyRate

  if (hasValueForV1Customer) {
    return {
      status: 'V1',
      value: financialPosition?.warrantyRate
    }
  } else if (hasValueForV2Customer) {
    return {
      status: 'V2',
      value: financialPosition?.warrantyAmount
    }
  }

  return
}
