import { type ExperimentValues } from '@stone-payments/experiment/src/lib/types'
import { isSwhExperimentOff } from 'swh/shared/utils'

import { Heimdall } from '~/domains/platform/core/heimdall'
import { NightOwl } from '~/domains/platform/core/night-owl/night-owl'
import { type Entity } from '~/domains/platform/infra/deus-ex-machina/types'
import { analytics } from '~/domains/platform/lib/analytics'

const handleSalesNavigation = () => {
  const hasLegacyFlow = Heimdall.pass(['sale_legacy_pf', 'sale_legacy_pj'], 'some')

  if (hasLegacyFlow) {
    return '/vendas'
  } else {
    return '/vendas/v2'
  }
}

const handlePaymentLinkNavigation = () => {
  const v2flow = Heimdall.pass(['charge_link_v2_pf', 'charge_link_v2_pj'], 'some')

  if (v2flow) {
    return '/link-de-pagamento/v2'
  } else {
    return '/link-de-pagamento'
  }
}

export async function acquirerMenu(entity: Entity, expData: ExperimentValues | undefined) {
  const hasAcquirerVision = entity.roles.acquirer
  const hasPaymentLinksResource = NightOwl.verifyScopes(['paymentaccount:paymentlinks:read'])

  const hasPaymentLinksFlag = Heimdall.pass(['payment_links'])

  return [
    {
      order: 7,
      label: 'Vendas',
      icon: 'shop-outline',
      show: isSwhExperimentOff(expData) && hasAcquirerVision,
      onClick: () => {
        analytics.sendEvent('home web - lateral menu button chosen', { lateralmenu: 'vendas' })
      },
      path: handleSalesNavigation(),
      active: (route: string) => ['/acquirer/sales', '/acquirer/sales/new-sales'].includes(route)
    },
    {
      order: 6,
      label: 'Recebimentos',
      icon: 'calendar-money-outline',
      show: hasAcquirerVision,
      onClick: () => {
        analytics.sendEvent('home web - lateral menu button chosen', { lateralmenu: 'recebimentos' })
      },
      path: '/recebimentos',
      active: (route: string) => ['/acquirer/receivables'].includes(route)
    },
    {
      order: 10,
      label: 'Antecipação',
      icon: 'round-clock-outline',
      show: hasAcquirerVision,
      onClick: () => {
        analytics.sendEvent('home web - lateral menu button chosen', { lateralmenu: 'antecipação' })
      },
      path: '/antecipacao',
      active: (route: string) => ['/acquirer/rav', '/acquirer/rav/history'].includes(route)
    },
    {
      order: 11,
      label: 'Maquininhas',
      icon: 'device-card-machine-outline',
      show: hasAcquirerVision,
      onClick: () => {
        analytics.sendEvent('home web - lateral menu button chosen', { lateralmenu: 'maquininhas' })
      },
      path: '/maquininhas',
      active: (route: string) => ['/acquirer/terminals'].includes(route)
    },
    {
      order: 12,
      label: 'Link de Pagamento',
      icon: 'paper-plane-outline',
      show: hasPaymentLinksFlag && hasPaymentLinksResource,
      onClick: () => {
        analytics.sendEvent('home web - lateral menu button chosen', { lateralmenu: 'link-de-pagamento' })
      },
      path: handlePaymentLinkNavigation(),
      active: (route: string) => ['/banking/payment-link', '/banking/payment-link-v2'].includes(route)
    },
    {
      order: 19,
      label: 'Relatórios',
      icon: 'file-folded-outline',
      show: hasAcquirerVision,
      onClick: () => {
        analytics.sendEvent('home web - lateral menu button chosen', { lateralmenu: 'relatórios' })
      },
      path: '/relatorios/tipos-de-relatorio',
      active: (route: string) => ['/acquirer/reports/[step]'].includes(route),
      isNew: true
    }
  ]
}
