import { type FC, type PropsWithChildren } from 'react'
import { Cover, Grid, Stack } from '@dlpco/fluid-layout'
import { Button, theme } from '@dlpco/ginga-stone'

import { Box, Flex, Text } from '~/domains/platform/design-system'
import BlackBird from '~/domains/platform/lib/blackbird'
import { If } from '~/domains/platform/lib/utilities-components'
import { useTheme } from '~/ui/hooks/utils/ui/use-theme'

import { type ErrorContainerProps } from './interface'

export const ErrorContainer: FC<React.PropsWithChildren<PropsWithChildren<ErrorContainerProps>>> = ({
  illustration,
  children,
  title,
  redirectHome,
  titleProps,
  style,
  alignItems = 'stretch'
}) => {
  const {
    sizes: {
      header: { height: headerHeight }
    }
  } = useTheme()

  return (
    <Box backgroundColor={theme.light.color.onColorHigh} style={style as React.CSSProperties}>
      <Cover centered=".center" space="4rem" minHeight={`calc(100vh - ${headerHeight})`}>
        <Grid className="center" gutter="2rem" min="300px">
          <Flex justifyContent="center" alignItems={alignItems}>
            {illustration}
          </Flex>
          <Stack space="1rem">
            <If
              condition={Boolean(title)}
              render={() => (
                <Text fontSize="large" color="primary" pb="medium" {...titleProps}>
                  {title}
                </Text>
              )}
            />
            {children}
            <If
              condition={Boolean(redirectHome)}
              render={() => (
                <Button role="link" onClick={() => BlackBird.travelTo('/home')} variant="contentOnly" size="large">
                  Ir para o início
                </Button>
              )}
            />
          </Stack>
        </Grid>
      </Cover>
    </Box>
  )
}
