import axios, { type AxiosTransformer } from 'axios'
import { PAYMENTS_RECEIVABLES_BFF } from 'shared/envs'

import { httpClientFactory } from '~/domains/platform/infra/http/http-client-factory'
import { transformResponse } from '~/domains/platform/infra/http/http-helpers'
import { responseUnauthorizedAccessInterceptor } from '~/domains/platform/infra/http/interceptors/unauthorized-access-interceptor'

export const httpClientPaymentsReceivableBff = httpClientFactory({
  baseURL: PAYMENTS_RECEIVABLES_BFF(),
  transformResponse: [...(axios.defaults.transformResponse as AxiosTransformer[]), transformResponse],
  responseErrorInterceptors: [responseUnauthorizedAccessInterceptor]
})
