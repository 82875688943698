import { PORTS__TWILIO__CUSTOM_API_ENDPOINT } from 'shared/envs'

import { useQueryRequest } from '~/ui/hooks/utils'

import { simpleHttpClient } from '../../http/simple-http-client'

const TWILIO_CUSTOM_URL = PORTS__TWILIO__CUSTOM_API_ENDPOINT()

async function getTwilioToken({ chatIdentity }: { chatIdentity: string }) {
  const {
    data: { token }
  } = await simpleHttpClient<{ token: string }>({
    baseURL: TWILIO_CUSTOM_URL,
    url: '/get-token',
    params: {
      identity: chatIdentity,
      platform: 'WEB'
    }
  })

  return token
}
export const useChatToken = ({ chatIdentity }: { chatIdentity: string }) => {
  return useQueryRequest<string, unknown, true>(['useChatToken', chatIdentity], () => getTwilioToken({ chatIdentity }))
}
