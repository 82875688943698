import { type ExperimentValues } from '@stone-payments/experiment/src/lib/types'

// eslint-disable-next-line @nx/enforce-module-boundaries
import { isValidCnpj } from '~/lib/helpers'

const FEATURE_FLAG_KEY = 'conta-stone-swh'

const isSwhExperiment = (expData: ExperimentValues | undefined, state: 'on' | 'off'): boolean => {
  return expData?.[FEATURE_FLAG_KEY]?.key !== undefined && expData?.[FEATURE_FLAG_KEY]?.key === state
}

export const isSwhExperimentOn = (expData: ExperimentValues | undefined, document: string) =>
  isSwhExperiment(expData, 'on') && isValidCnpj(document)

export const isSwhExperimentOff = (expData: ExperimentValues | undefined) => {
  return expData?.[FEATURE_FLAG_KEY]?.key === undefined || isSwhExperiment(expData, 'off')
}
