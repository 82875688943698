import { format, parse, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale/pt-BR'
export function formatDateWithHours(date: string): string {
  const parseDate = parseISO(date)
  return format(parseDate, 'dd/MM/yyyy HH:mm')
}

export function formatDateTextDateWithHours(date: string) {
  const parseDate = parseISO(date)
  return format(parseDate, "d MMM 'às' HH:mm", { locale: ptBR })
}
export function formatDateWithoutHours(date: string) {
  const parseDate = parseISO(date)
  return format(parseDate, 'dd MMM yyy', { locale: ptBR })
}

export function formatSimpleBrDate(date: string) {
  const parseDate = parseISO(date)

  return format(parseDate, 'dd/MM/yyyy', { locale: ptBR })
}

type FMT = 'dd/MM/yyyy' | "d MMM 'às' HH:mm" | 'dd/MM/yyyy HH:mm' | 'HH:mm' | 'dd MMM yyy'

export const dateFormat = (date: string, fmt: FMT) => {
  return format(date, fmt, { locale: ptBR })
}

export const dateParse = (formattedDate: string, fmt: FMT, date = new Date()) => {
  return parse(formattedDate, fmt, date, { locale: ptBR })
}
