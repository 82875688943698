import { type Dispatch, type ReactNode, type SetStateAction, createContext, useContext, useState } from 'react'
import { type Stores } from 'swh/shared/http'

type State = { store: Stores.Item; affiliationKey: string; merchant?: { document: string } }

const defaultState = {
  store: {
    id: '',
    name: '',
    document: '',
    stateRegistration: '',
    cnae: ''
  },
  merchant: {
    document: ''
  },
  affiliationKey: null
}

const SwhConfigContext = createContext<[state: State, setState: Dispatch<SetStateAction<State>>]>([
  {} as State,
  () => {}
])

export const SwhConfigContextProvider = ({ children }: { children: ReactNode }) => {
  const value = useState<State>({} as State)
  return <SwhConfigContext.Provider value={value}>{children}</SwhConfigContext.Provider>
}

export const useSwhConfigContext = () => useContext(SwhConfigContext)
// @ts-ignore CREDIT CHANGE TO RUN CHECK-TYPES SUCCESSFULLY
export const useSwhConfig = (): State => {
  const [state] = useContext(SwhConfigContext)
  return state || defaultState
}
