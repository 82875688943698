import { useEffect, useState } from 'react'
import { Variants } from 'swh/shared/http'

export const useVariants = (storeId: string | undefined) => {
  const [variants, setVariants] = useState<Variants.VariationType[]>([])

  useEffect(() => {
    if (storeId) Variants.get(storeId).then(setVariants)
  }, [storeId])

  return variants
}
