import { loanInstallmentsMock } from '~/domains/credit/loans/loan-details/components/loan-details.mock'
import { contractsSuccess } from '~/scaffold/builders/credit/revolving-credit/contract'
import { type ScenarioMock } from '~/scaffold/mocks/types/mock-flags-types'

export const loanRenegociationGrace: ScenarioMock[] = [
  {
    scenario: 'Renegociação em processo',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/api/web/v1/home?include_product_type=Revolving&include_product_type=Loan&document=**',
        status: 200,
        delay: 0,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
          content: {
            sections: [
              {
                id: 'loans',
                title: 'Home.Sections.Loans.Title',
                elements: [
                  {
                    id: 'jr3kb41u9b6pbap4422e49ujw',
                    type: 'processing_loan',
                    status: 'active',
                    status_description: 'Ativo',
                    credit_amount: 2022864,
                    total_of_installment: 10,
                    initial_installment_amount: 225081,
                    installment_plan_id: 'jr3kfct7fhqp4bw13r27mxuny',
                    creation_date: '2024-04-01T13:36:38Z',
                    last_update_date: '2024-04-01T17:40:16Z',
                    is_retention_enabled: true,
                    grace_period_in_days: 60,
                    plans: [
                      {
                        amount_by_installment: {
                          value: 102.33,
                          description: 'R$ 102,33',
                          type: 'money'
                        },
                        installments: {
                          value: 2,
                          description: '2x',
                          type: 'integer'
                        },
                        total: {
                          value: 204.67,
                          description: 'R$ 204,67',
                          type: 'money'
                        },
                        type: 'GraceWithInterest'
                      },
                      {
                        amount_by_installment: {
                          value: 102.33,
                          description: 'R$ 102,33',
                          type: 'money'
                        },
                        installments: {
                          value: 2,
                          description: '2x',
                          type: 'integer'
                        },
                        total: {
                          value: 204.67,
                          description: 'R$ 204,67',
                          type: 'money'
                        },
                        type: 'Regular'
                      }
                    ],
                    current_installment: {
                      installment_number: 1,
                      start_date: '2024-04-01T00:00:00Z',
                      due_date: '2024-05-03',
                      days_until_due_date: 0,
                      is_due_soon: false,
                      days_overdue: 157,
                      is_overdue: true,
                      status: 'overdue',
                      paid_amount: 0,
                      initial_amount: 225081,
                      future_balance: 225081,
                      present_balance: 225081,
                      interest_economy_amount: 0,
                      penalty_fee_amount: 0,
                      penalty_interest_amount: 0,
                      late_payment_interest_amount: 0,
                      total_debt_amount: 4392
                    },
                    next_installment: {
                      installment_number: 2,
                      start_date: '2024-05-04T00:00:00Z',
                      due_date: '2024-06-03T00:00:00Z',
                      future_balance: 225081,
                      present_balance: 225081
                    },
                    overdue_installment: {
                      overdue_installments: [
                        {
                          installment_number: 1,
                          due_date: '2024-05-03',
                          present_balance: 225081
                        }
                      ],
                      total_present_balance: 1350484,
                      days_overdue: 157,
                      penalty_fee_amount: 0,
                      penalty_interest_amount: 0,
                      late_payment_interest_amount: 0
                    },
                    renegotiation_summary: {
                      status: 'Active',
                      status_description: 'Renegociação ativa',
                      loan_renegotiation_contract_id: 'jr3kfbqmc1ag9asfvsn8wzg3u',
                      contract_signature_date: '2024-04-01'
                    }
                  }
                ]
              }
            ]
          },
          trace_key: 'ff31c91c7a6d4ebfa640c7e1b',
          is_valid: true
        })
      }
    ]
  },
  {
    scenario: 'Renegociado com parcelas variáveis',
    mocks: [
      {
        method: 'GET',
        url: '**/experience-bff-service/api/web/v1/home?include_product_type=Revolving&include_product_type=Loan&document=**',
        status: 200,
        delay: 0,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
          content: {
            sections: [
              {
                id: 'loans',
                title: 'Home.Sections.Loans.Title',
                elements: [
                  {
                    id: 'jr3kb41u9b6pbap4422e49ujw',
                    type: 'current_loan',
                    status: 'active',
                    status_description: 'Ativo',
                    credit_amount: 2022864,
                    total_of_installment: 10,
                    initial_installment_amount: 225081,
                    installment_plan_id: 'jr3kfct7fhqp4bw13r27mxuny',
                    creation_date: '2024-04-01T13:36:38Z',
                    last_update_date: '2024-04-01T17:40:16Z',
                    is_retention_enabled: true,
                    grace_period_in_days: 60,
                    plans: [
                      {
                        amount_by_installment: {
                          value: 1020.33,
                          description: 'R$ 1020,33',
                          type: 'money'
                        },
                        installments: {
                          value: 2,
                          description: '2x',
                          type: 'integer'
                        },
                        total: {
                          value: 2040.67,
                          description: 'R$ 2040,67',
                          type: 'money'
                        },
                        type: 'GraceWithInterest'
                      },
                      {
                        amount_by_installment: {
                          value: 502.33,
                          description: 'R$ 502,33',
                          type: 'money'
                        },
                        installments: {
                          value: 4,
                          description: '4x',
                          type: 'integer'
                        },
                        total: {
                          value: 2040.67,
                          description: 'R$ 2040,67',
                          type: 'money'
                        },
                        type: 'Regular'
                      }
                    ],
                    current_installment: {
                      installment_number: 1,
                      start_date: '2024-04-01T00:00:00Z',
                      due_date: '2026-05-03',
                      days_until_due_date: 0,
                      is_due_soon: false,
                      days_overdue: 157,
                      is_overdue: false,
                      status: 'open',
                      paid_amount: 0,
                      initial_amount: 225081,
                      future_balance: 225081,
                      present_balance: 225081,
                      interest_economy_amount: 0,
                      penalty_fee_amount: 0,
                      penalty_interest_amount: 0,
                      late_payment_interest_amount: 0,
                      total_debt_amount: 4392
                    },
                    next_installment: {
                      installment_number: 2,
                      start_date: '2024-05-04T00:00:00Z',
                      due_date: '2024-06-03T00:00:00Z',
                      future_balance: 225081,
                      present_balance: 225081
                    },
                    overdue_installment: {
                      overdue_installments: [
                        {
                          installment_number: 1,
                          due_date: '2024-05-03',
                          present_balance: 225081
                        }
                      ],
                      total_present_balance: 1350484,
                      days_overdue: 157,
                      penalty_fee_amount: 0,
                      penalty_interest_amount: 0,
                      late_payment_interest_amount: 0
                    },
                    renegotiation_summary: {
                      status: 'Active',
                      status_description: 'Renegociação ativa',
                      loan_renegotiation_contract_id: 'jr3kfbqmc1ag9asfvsn8wzg3u',
                      contract_signature_date: '2024-04-01'
                    }
                  }
                ]
              }
            ]
          },
          trace_key: 'ff31c91c7a6d4ebfa640c7e1b',
          is_valid: true
        })
      },
      {
        method: 'GET',
        url: '**/experience-bff-service/api/legacy/web/v1/loans/**/summary',
        status: 200,
        delay: 0,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({
          current_summary: {
            installment_plan_id: 'jr2we5bejbpqfy5h8qenaj8b4',
            installment_plan_origin_type: 'WorkingCapitalRenegotiation',
            label: 'Empréstimo',
            initial_amount: 22510.87,
            initial_installment_amount: 2251.09,
            future_balance: 24019.32,
            current_balance: 24019.32,
            present_balance: 23670.04,
            credit_amount: 20231.16,
            tax_amount: 0.0,
            interest_economy_amount: 0.0,
            paid_amount: 0.0,
            number_of_installments: 10,
            number_of_settled_installments: 0,
            number_of_overdue_installments: 6,
            number_of_outstanding_installments: 10,
            is_retention_enabled: true,
            retention_rate: 0.19,
            monthly_interest_rate: 0.0199,
            yearly_interest_rate: 0.2668,
            yearly_total_effective_cost_rate: 0.2668,
            due_date: '2025-01-06',
            contract_uri:
              'https://credit-sandbox.stone.com.br/contracts-service/api/v1/contracts/jr2wbyyg7p6vfqtk3291vaade',
            issuer: {
              name: 'Stone Sociedade de Credito Direto S.A.',
              document_number: '34590184000109',
              document_type: 'cnpj'
            },
            holder: {
              name: 'SOMA III Fundo de Investimento em Direitos Creditorios',
              document_number: '38314763000180',
              document_type: 'cnpj'
            },
            grace_period_in_days: 60,
            plans: [
              {
                amount_by_installment: {
                  value: 102.33,
                  description: 'R$ 102,33',
                  type: 'money'
                },
                installments: {
                  value: 2,
                  description: '2x',
                  type: 'integer'
                },
                total: {
                  value: 204.67,
                  description: 'R$ 204,67',
                  type: 'money'
                },
                type: 'GraceWithInterest'
              },
              {
                amount_by_installment: {
                  value: 102.33,
                  description: 'R$ 102,33',
                  type: 'money'
                },
                installments: {
                  value: 2,
                  description: '2x',
                  type: 'integer'
                },
                total: {
                  value: 204.67,
                  description: 'R$ 204,67',
                  type: 'money'
                },
                type: 'Regular'
              }
            ],
            current_installment: {
              id: 'jr2we5bg3c6h9tpcf2qffmxnd',
              installment_number: 1,
              start_date: '2024-03-04',
              due_date: '2024-04-08',
              days_until_due_date: 0,
              is_due_soon: false,
              days_overdue: 165,
              is_collection_enabled: true,
              is_overdue: true,
              status: 'Overdue',
              paid_amount: 0.0,
              initial_amount: 2251.09,
              future_balance: 2677.59,
              present_balance: 2677.59,
              interest_economy_amount: 0.0,
              penalty_fee_amount: 45.02,
              penalty_interest_amount: 123.81,
              late_payment_interest_amount: 257.67,
              total_interest_and_fee_sum_amount: 426.5,
              total_debt_amount: 470.43
            },
            overdue_installments_summary: {
              overdue_installments: [
                {
                  installment_number: 1,
                  due_date: '2024-04-08',
                  present_balance: 2677.59
                },
                {
                  installment_number: 2,
                  due_date: '2024-05-06',
                  present_balance: 2610.86
                },
                {
                  installment_number: 3,
                  due_date: '2024-06-06',
                  present_balance: 2537.96
                },
                {
                  installment_number: 4,
                  due_date: '2024-07-08',
                  present_balance: 2463.75
                },
                {
                  installment_number: 5,
                  due_date: '2024-08-06',
                  present_balance: 2397.4
                },
                {
                  installment_number: 6,
                  due_date: '2024-09-06',
                  present_balance: 2327.41
                }
              ],
              total_present_balance: 15014.97,
              days_overdue: 165,
              penalty_fee_amount: 270.13,
              penalty_interest_amount: 405.95,
              late_payment_interest_amount: 832.38,
              total_interest_and_fee_sum_amount: 1508.46
            },
            next_installment: {
              installment_number: 2,
              start_date: '2024-04-07',
              due_date: '2024-05-06',
              status: 'Overdue',
              future_balance: 2610.86,
              present_balance: 2610.86
            }
          },
          previous_summary: {
            installment_plan_id: 'jr2wbaatrd86e3c3dnhr35t5b',
            installment_plan_origin_type: 'WorkingCapitalConcession',
            label: 'Empréstimo',
            initial_amount: 21663.39,
            initial_installment_amount: 3610.57,
            future_balance: 20231.16,
            current_balance: 20231.16,
            present_balance: 0.0,
            credit_amount: 20000.0,
            tax_amount: 231.16,
            interest_economy_amount: 0.0,
            paid_amount: 0.0,
            number_of_installments: 6,
            number_of_settled_installments: 6,
            number_of_overdue_installments: 0,
            number_of_outstanding_installments: 0,
            is_retention_enabled: true,
            retention_rate: 0.19,
            monthly_interest_rate: 0.0199,
            yearly_interest_rate: 0.2668,
            yearly_total_effective_cost_rate: 0.3186,
            due_date: '2024-09-16',
            contract_uri:
              'https://credit-sandbox.stone.com.br/contracts-service/api/v1/contracts/jr2wayyc1sss7xw4k9rfcx69q',
            issuer: {
              name: 'Stone Sociedade de Credito Direto S.A.',
              document_number: '34590184000109',
              document_type: 'cnpj'
            },
            holder: {
              name: 'SOMA III Fundo de Investimento em Direitos Creditorios',
              document_number: '38314763000180',
              document_type: 'cnpj'
            },
            current_installment: {
              id: 'jr2wbab1116s2m1f2w78repbu',
              installment_number: 6,
              start_date: '2024-08-15',
              due_date: '2024-09-16',
              days_until_due_date: 0,
              is_due_soon: false,
              days_overdue: 4,
              settlement_date: '2024-03-04',
              is_collection_enabled: false,
              is_overdue: false,
              status: 'Settled',
              paid_amount: 0.0,
              initial_amount: 3610.57,
              future_balance: 402.6,
              present_balance: 0.0,
              interest_economy_amount: 0.0,
              penalty_fee_amount: 0.0,
              penalty_interest_amount: 0.0,
              late_payment_interest_amount: 0.0,
              total_interest_and_fee_sum_amount: 0.0,
              total_debt_amount: 3610.57
            },
            overdue_installments_summary: {
              overdue_installments: [],
              total_present_balance: 0.0,
              days_overdue: 0,
              penalty_fee_amount: 0.0,
              penalty_interest_amount: 0.0,
              late_payment_interest_amount: 0.0,
              total_interest_and_fee_sum_amount: 0.0
            }
          },
          renegotiation_summary: {
            status: 'Active',
            status_description: 'Renegociação ativa',
            loan_renegotiation_contract_id: 'jr2wbyyg7p6vfqtk3291vaade',
            contract_signature_date: '2024-03-04'
          }
        })
      },
      {
        method: 'GET',
        url: '**/experience-bff-service/api/legacy/web/v1/installment-plans/**/installments',
        status: 200,
        delay: 0,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(loanInstallmentsMock)
      },
      {
        method: 'GET',
        url: '**/contracts-service/api/v1/contracts?id=**',
        status: 200,
        delay: 0,
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(contractsSuccess)
      }
    ]
  }
]
