import {
  BAIXADA,
  CANCELED,
  CHIP_CHANGE,
  DELIVERY_PAPER_ROLL,
  DIRECT_REPLACEMENT,
  DIRECT_REPLACEMENT_CHIP,
  IN_FIELD,
  IN_TRANSFER,
  IN_TREATMENT,
  INSTALL,
  MAINTENANCE,
  NEW,
  PAPER_ROLL,
  PINPAD_INSTALL,
  PINPAD_REPLACEMENT,
  PINPAD_UNINSTALL,
  PRE_BAIXADA,
  RECEIVED,
  REPIQUE,
  REPLACEMENT,
  SEND_PAPER_ROLES,
  SEND_PAPER_ROLL,
  SEND_STICKER,
  SERVICE_UNINSTALL,
  STICKER,
  SUPPLIES,
  TECHNICAL_VISIT,
  TERMINAL,
  TREATMENT_N1,
  TREATMENT_N2,
  UNINSTALL
} from './constants'

export const serviceTypes: Record<string, string> = {
  SEND_STICKER,
  SEND_PAPER_ROLL,
  SEND_PAPER_ROLES,
  DELIVERY_PAPER_ROLL,
  PINPAD_UNINSTALL,
  UNINSTALL,
  PINPAD_INSTALL,
  INSTALL,
  CHIP_CHANGE,
  DIRECT_REPLACEMENT_CHIP,
  PINPAD_REPLACEMENT,
  DIRECT_REPLACEMENT
}

export const serviceGroup: Record<string, string> = {
  REPLACEMENT,
  INSTALL,
  SUPPLIES,
  UNINSTALL,
  MAINTENANCE
}

export const terminalStatusServiceOrders: Record<string, string> = {
  NEW,
  RECEIVED,
  IN_TREATMENT,
  TREATMENT_N1,
  TREATMENT_N2,
  REPIQUE,
  IN_TRANSFER,
  CANCELED,
  BAIXADA,
  IN_FIELD,
  PRE_BAIXADA
}

export const serviceTranslated: Record<string, string> = {
  PAPER_ROLL,
  TECHNICAL_VISIT,
  TERMINAL,
  STICKER,
  SERVICE_UNINSTALL
}

export function verifyServiceType(serviceType: string, serviceGroup: string) {
  if (
    ([DIRECT_REPLACEMENT, PINPAD_REPLACEMENT].includes(serviceType) && serviceGroup === REPLACEMENT) ||
    ([CHIP_CHANGE, DIRECT_REPLACEMENT_CHIP].includes(serviceType) && serviceGroup === MAINTENANCE)
  ) {
    return TECHNICAL_VISIT
  }

  if ([INSTALL, PINPAD_INSTALL].includes(serviceType) && serviceGroup === INSTALL) {
    return TERMINAL
  }

  if (serviceGroup === SUPPLIES) {
    if ([SEND_PAPER_ROLL, SEND_PAPER_ROLES, DELIVERY_PAPER_ROLL].includes(serviceType)) {
      return PAPER_ROLL
    }
    if (serviceType === SEND_STICKER) {
      return STICKER
    }
  }

  if ([UNINSTALL, PINPAD_UNINSTALL].includes(serviceType) && serviceGroup === UNINSTALL) {
    return SERVICE_UNINSTALL
  }
  return ''
}
