import { setupWorker } from 'msw'

import { type Mock, type TempMock, mockFlagsList } from './types/mock-flags-types'
import { MockFlagsStorage, TempMockFlagsStorage } from './utils/mock-flags-storage'
import { createHandlersFromJson } from './utils/msw-handler-json'

const handleMockName = ({
  domain,
  flagName,
  scenario,
  index
}: {
  domain: string
  flagName: string
  scenario: string
  index: number
}) => {
  return `${domain}-${flagName}-${scenario}-mock-${index}`
}

const allMockFlags = mockFlagsList
  .map(mockFlag =>
    mockFlag.mocks.map(mock => {
      return { ...mock, domain: mockFlag.domain }
    })
  )
  .flat()

const flags = mockFlagsList.flatMap(mockFlag => mockFlag.mocks)

const getActiveHandlers = () => {
  const { mockFlags } = MockFlagsStorage.get()
  const tempMocks = TempMockFlagsStorage.get() ?? []
  const activeTempMocks: TempMock[] = tempMocks.filter(mock => mock.tempHandler !== undefined && mock.checked === true)
  const tempHandlers: Mock[] = activeTempMocks
    .map(mock => mock.tempHandler)
    .filter((handler): handler is Mock => handler !== undefined)

  const activeHandlers = allMockFlags
    .flatMap(mockFlag => {
      const flagHandlers = mockFlag.handlers
        .map((handler, index) => {
          return { ...handler, index }
        })
        .filter(handler =>
          mockFlags.some(flag => mockFlag.name === flag.name && handler.scenario === flag.currentScenario)
        )
        .flatMap(flag => {
          return flag.mocks.map((mock, index) => {
            return {
              ...mock,
              name: handleMockName({
                domain: mockFlag.domain,
                flagName: mockFlag.name,
                index: index,
                scenario: flag.scenario
              }),
              flag: mockFlag.name
            }
          })
        })
      return flagHandlers
    })
    .filter(mock => !activeTempMocks.some(tempMock => tempMock.name === mock.name))

  const activeFlagHandlers = activeHandlers.length > 0 ? createHandlersFromJson(activeHandlers) : []
  const activeTempHandlers = tempHandlers.length > 0 ? createHandlersFromJson(tempHandlers) : []

  return [...activeFlagHandlers, ...activeTempHandlers]
}

const updateWorkerHandlers = async () => {
  if (typeof window !== 'undefined') {
    const worker = setupWorker()
    if (worker) {
      try {
        await worker.start({ onUnhandledRequest: 'bypass' })
        worker.resetHandlers()
        worker.use(...getActiveHandlers())
      } catch (error) {
        console.error(error)
      }
    }
  }
}

const setupMocks = () => setupWorker(...getActiveHandlers())

export { flags, getActiveHandlers, setupMocks, updateWorkerHandlers }
