import { saveAs } from 'file-saver'

import {
  type PixDynamicWithDueDatePaymentInvoices,
  type PixKeyStatus,
  type PixKeyType,
  type PixPaymentInvoices,
  type PixPaymentStaticInvoices
} from '~/domains/banking/pix/shared/types'
import { EMAIL_REGEX, RANDOM_PIX_KEY_REGEX } from '~/lib/constants'
import { isValidCnpj, isValidCpf, isValidPhone, unmask } from '~/lib/helpers'

export const transcribePixKeyType = new Map<PixKeyType | string, string>([
  ['cpf', 'CPF'],
  ['cnpj', 'CNPJ'],
  ['phone', 'Celular'],
  ['email', 'E-mail'],
  ['random_key', 'Chave aleatória']
])

export const transcribePixKeyStatus = new Map<PixKeyStatus | '', string>([
  ['delete_requested', 'Exclusão em andamento'],
  ['deleted', ''],
  ['failed', 'Chave não cadastrada'],
  ['key_created', 'Ativa desde ${date}'],
  ['ownership_claimed', 'Reivindicação de posse em andamento'],
  ['portability_claimed', 'Portabilidade em andamento'],
  ['requested', 'Cadastro em andamento']
])

export const pixIconColorByStatus = (theme: any) =>
  new Map<PixKeyStatus | '', any>([
    ['delete_requested', theme.colors.danger],
    ['deleted', theme.colors.primary],
    ['failed', theme.colors.danger],
    ['key_created', theme.colors.primary],
    ['ownership_claimed', ''],
    ['portability_claimed', ''],
    ['requested', theme.colors.yellow]
  ])

export const pixTextStatusColorByStatus = (theme: any) =>
  new Map<PixKeyStatus, any>([
    ['delete_requested', ''],
    ['deleted', ''],
    ['failed', theme.colors.mediumGray],
    ['key_created', theme.colors.primary],
    ['ownership_claimed', ''],
    ['portability_claimed', ''],
    ['requested', theme.colors.mediumGray]
  ])

export const exportQRCodeImage = async (
  pixKey: PixDynamicWithDueDatePaymentInvoices | PixPaymentStaticInvoices | PixPaymentInvoices,
  filename?: string
) => {
  const blob = await fetch(pixKey.qrCodeImage)
    .then(res => res.blob())
    .then(blob => {
      saveAs(blob, filename)
    })

  return blob
}

export const pixKeyIdentifier = (rawValue: string): [PixKeyType | 'unknown', boolean] => {
  const hasDoubt = isValidCpf(rawValue) && isValidPhone(rawValue)

  if (hasDoubt) return ['cpf', true]

  const keyIdentifier: Record<string, (value: string) => boolean> = {
    isCpfKey: value => isValidCpf(value) && !isValidPhone(value),
    isCnpjKey: value => isValidCnpj(value),
    isPhone: value => !isValidCpf(value) && isValidPhone(value),
    isRandomKey: value => RANDOM_PIX_KEY_REGEX.test(value),
    isEmail: value => EMAIL_REGEX.test(value)
  }

  const unmaskedValue = unmask(rawValue)

  if (keyIdentifier.isCpfKey(unmaskedValue)) return ['cpf', false]
  if (keyIdentifier.isPhone(unmaskedValue)) return ['phone', false]
  if (keyIdentifier.isCnpjKey(unmaskedValue)) return ['cnpj', false]
  if (keyIdentifier.isRandomKey(unmaskedValue)) return ['random_key', false]
  if (keyIdentifier.isEmail(rawValue)) return ['email', false]
  return ['unknown', false]
}
