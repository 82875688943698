/* istanbul ignore file */

import { entityFingerprint, personFingerprint, subjectFingerprint } from '../helpers/assertions'
import { type Entity, type OptIns, type PaymentAccount, type Person, type Subject } from '../types'

export const personBuilder = (overrides: Partial<Person> = {}): Person => ({
  [personFingerprint]: true,
  document: '63279091080',
  documentType: 'CPF',
  email: 'cleiton@finances.com.br',
  displayName: 'Cleiton',
  id: '4e30841b-5737-4f0d-afc5-590e5334d002',
  createdAt: '2022-11-29T20:21:32.225Z',
  approvalStatus: 'approved',
  roles: { acquirer: true, banking: true, credit: true },
  unsafe: {
    fullName: 'Cleiton da Silva Santos',
    legalName: 'Cleitinho'
  },
  ...overrides
})

export const paymentAccountBuilder = (overrides: Partial<PaymentAccount> = {}): PaymentAccount => ({
  accountCode: '123456',
  branchCode: '0001',
  id: '2d10a9b3-700c-4563-96d3-c63981907825',
  createdAt: '2022-11-28T20:21:32.225Z',
  ownerDocument: '46898533800',
  ownerId: 'c86bb14a-e5f3-4a21-a532-0fbf21ceb113',
  ownerName: 'Cleiton',
  status: 'ACTIVE',
  enabledFeatures: [],
  ownerKycStatus: 'approved',
  ...overrides
})

export const subjectBuilder = (overrides: Partial<Subject<OptIns>> = {}): Subject<OptIns> => ({
  [subjectFingerprint]: true,
  email: 'cleiton@finances.com.br',
  emailVerified: true,
  id: 'c86bb14a-e5f3-4a21-a532-0fbf21ceb113',
  phoneNumber: '+5511999999999',
  deviceType: 'unregistered_device',
  displayName: 'Cleiton Souza',
  address: {
    city: 'São Paulo',
    country: 'BR',
    neighborhood: 'Vila Olímpia',
    postalCode: '00000000',
    state: 'SP',
    street: 'Rua Faria Lima',
    streetNumber: '0000'
  },
  document: '46898533800',
  kycStatus: 'approved',
  approvalStatus: 'approved',
  challengeBlocked: false,
  organizations: [personBuilder()],
  users: [personBuilder()],
  kycRequest: undefined,
  changeEmailRequest: {
    expiresAt: '2023-03-22T22:13:30.772356',
    id: 'd4444b0a3-feec-4455f-9d02-979ec37248ef',
    securityCheck: {
      channels: ['email'],
      channelsValues: {
        email: 'cleiton.cleitinho@stone.com.br'
      },
      expiresAt: '2023-03-22T22:13:30.772356',
      id: 'b5e5e414-4558-4ca5-8ad7-03b735c2e0ee'
    },
    status: 'security_verification'
  },
  paymentAccounts: [paymentAccountBuilder()],
  showKycChecks: true,
  kycApproved: true,
  rejectedKycChecks: [],
  createdAt: '2022-11-29T20:21:32.225Z',
  credentials: [],
  unsafe: {
    fullName: 'Cleiton da Silva Santos',
    legalName: 'Cleitinho',
    hasAnActivePaymentAccount: true,
    roles: { banking: true, acquirer: true, credit: true },
    entitiesKycRequests: [],
    stoneCodes: []
  },
  enabledFeatures: [],
  ...overrides
})

export const entityBuilder = (overrides: Partial<Entity<OptIns>> = {}): Entity<OptIns> => ({
  [entityFingerprint]: true,
  ...personBuilder(),
  kycStatus: 'approved',
  paymentAccount: paymentAccountBuilder(),
  stoneCodes: [],
  hasAnActivePaymentAccount: true,
  enabledFeatures: [],
  rejectedKycChecks: [],
  ...overrides
})

export const deusExMachinaBuilder = (
  overrides: Partial<{ entity: Entity<OptIns>; subject: Subject<OptIns> }> = {}
): { entity: Entity<OptIns>; subject: Subject<OptIns> } => ({
  subject: subjectBuilder(),
  entity: entityBuilder(),
  ...overrides
})
