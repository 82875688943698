import { type FlagProps } from '~/scaffold/mocks/types/mock-flags-types'

import { spotOfferCreatePayment } from './spot-offer-create-payment.mock'
import { spotOfferWithGeneratedPayment } from './spot-offer-with-generated-payment.mock'

const loanSpotOfferFlags: FlagProps[] = [
  {
    name: 'spot-offer-create-payment',
    groupName: 'Fluxo de pagamento pontual',
    scenarios: ['Sucesso - Pix', 'Sucesso - Boleto', 'Erro', 'Link por email'],
    initialScenario: 'Sucesso - Pix',
    handlers: spotOfferCreatePayment
  },
  {
    name: 'spot-offer-with-generated-payment',
    groupName: 'Fluxo de pagamento pontual',
    scenarios: ['Pix', 'Boleto'],
    initialScenario: 'Pix',
    handlers: spotOfferWithGeneratedPayment
  }
]

export { loanSpotOfferFlags }
