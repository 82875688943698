import { type AxiosError, type AxiosResponse } from 'axios'
import {
  CREDIT_API_ENDPOINT,
  CUSTOMER_API_ENDPOINT,
  CUSTOMER_API_ENDPOINT_V1,
  PORTAL_API_ENDPOINT,
  RAV_API_ENDPOINT
} from 'shared/envs'

import { appEnv } from '~/lib/helpers/utils/environment'
import { isomorphicPush } from '~/lib/helpers/utils/isomorphic-push'

const defaultAllowStatus401 = [
  /\/documents\/dirf/i,
  /\/organizations\/payments/i,
  /\/payments\/wallet-types/i,
  /\/users\/current\/stonecodes/i,
  /\/transactions/i,
  /\/acquirer\/receivables/i,
  /\/logr\/report/i,
  /\/credit-lines/i,
  /\/loans/i,
  /\/authenticate\/validatePassword/i,
  /\/customer\/[0-9]{11,14}\/(agreements|condition|financial-position)$/i,
  /\/(merchants|affiliations)\/[0-9]{9}\/bank-accounts/i
]

const homologAllowStatus401 = [
  CUSTOMER_API_ENDPOINT(),
  RAV_API_ENDPOINT(),
  PORTAL_API_ENDPOINT(),
  CUSTOMER_API_ENDPOINT_V1(),
  CREDIT_API_ENDPOINT()
]
  .filter(Boolean)
  .map(apiUrl => new RegExp(String(apiUrl), 'i'))

function shouldRedirect(url?: string) {
  const allowStatus401 = appEnv.isPoitingToHomolog()
    ? [...homologAllowStatus401, ...defaultAllowStatus401]
    : defaultAllowStatus401

  return !allowStatus401.some(apiUrl => apiUrl.test(url as string))
}

export async function allowStatus401Interceptor(error: AxiosError) {
  const { status, config } = error?.response || ({} as AxiosResponse)

  if (status === 401) {
    if (shouldRedirect(`${config.baseURL}${config.url}`)) {
      isomorphicPush(`/sair?shouldRedirect=true`)

      return Promise.reject({ ...error, isRedirecting: true })
    }

    return Promise.reject(error)
  }

  return Promise.reject(error)
}
