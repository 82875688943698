import { unmask } from '~/lib/helpers'
import { httpClientRav } from '~/lib/services/acquirer/http-client-rav/http-client-rav'
import { useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

interface PrepayAgreementFinancialPosition {
  averageDuration: number
  negativeBalance: number
  grossReceivablesAmount: number
  netReceivablesAmount: number
  warrantyRate: number
  warrantyAmount: number
}

interface ApiError {
  type: string
  description: string
}

interface ApiErrorResponseData {
  errors: ApiError[]
}

export const fetchFinancialPosition = (document: string, affiliationKey?: string, fetcher = httpClientRav) => {
  const url = `/customer/${unmask(document)}/financial-position`

  return fetcher<PrepayAgreementFinancialPosition>({
    url,
    params: {
      customerExternalKey: affiliationKey
    }
  })
}

export function useFinancialPosition(document: string, affiliationKey?: string) {
  const { data: axiosResponse, ...restQuery } = useQueryRequest<PrepayAgreementFinancialPosition, ApiErrorResponseData>(
    ['useFinancialPosition', document, affiliationKey],
    () => fetchFinancialPosition(document, affiliationKey),
    {
      enabled: Boolean(affiliationKey)
    }
  )

  return { data: axiosResponse?.data, ...restQuery }
}
