import { type FlagProps } from '../../types/mock-flags-types'

import { loanSpotOfferFlags } from './loans/spot-offer'
import { creditHomeFlags } from './home'

const handlers: FlagProps[] = [...loanSpotOfferFlags, ...creditHomeFlags]

export const creditHandlers = handlers

export const creditMockFlags: { domain: string; mocks: FlagProps[] } = {
  domain: 'Crédito',
  mocks: creditHandlers
}
