import { type DefaultBodyType, type MockedRequest, type RestHandler, rest } from 'msw'

import { type Mock } from '../types/mock-flags-types'

/**
 * Cria handlers do MSW a partir dos dados JSON fornecidos.
 *
 * @param mockData - Dados JSON contendo as informações dos mocks e cenários.
 * @returns Array de handlers do MSW gerados a partir dos dados JSON.
 */
export function createHandlersFromJson(mocks: Mock[]): RestHandler<MockedRequest<DefaultBodyType>>[] {
  return mocks.map(({ method, url, status, delay = 0, headers = {}, body = '' }: Mock) => {
    const mswMethod = String(method).toLowerCase() as 'get' | 'post' | 'put' | 'delete' | 'patch'

    return rest[mswMethod](url, (_req, res, ctx) => {
      const response = res(
        ctx.status(status),
        ctx.delay(delay),
        ...Object.entries(headers).map(([key, value]) => ctx.set(key, value)),
        ctx.body(body)
      )

      return response
    })
  })
}
