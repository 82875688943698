import { PORTS__TWILIO__CUSTOM_API_ENDPOINT } from 'shared/envs'

import { simpleHttpClient } from '../http/simple-http-client'

import { type DepartmentsList } from './entities'

const TWILIO_CUSTOM_URL = PORTS__TWILIO__CUSTOM_API_ENDPOINT()

export function getDepartmentsList() {
  return simpleHttpClient<DepartmentsList>({ baseURL: TWILIO_CUSTOM_URL, url: '/teams' })
}
