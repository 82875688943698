export const CameraIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_5642_5025"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99992 6C7.29268 6 6.6144 6.28095 6.1143 6.78105C5.6142 7.28115 5.33325 7.95942 5.33325 8.66667C5.33325 9.37391 5.6142 10.0522 6.1143 10.5523C6.6144 11.0524 7.29268 11.3333 7.99992 11.3333C8.70716 11.3333 9.38544 11.0524 9.88554 10.5523C10.3856 10.0522 10.6666 9.37391 10.6666 8.66667C10.6666 7.95942 10.3856 7.28115 9.88554 6.78105C9.38544 6.28095 8.70716 6 7.99992 6ZM7.05711 7.72386C7.30716 7.47381 7.6463 7.33333 7.99992 7.33333C8.35354 7.33333 8.69268 7.47381 8.94273 7.72386C9.19278 7.97391 9.33325 8.31304 9.33325 8.66667C9.33325 9.02029 9.19278 9.35943 8.94273 9.60948C8.69268 9.85952 8.35354 10 7.99992 10C7.6463 10 7.30716 9.85952 7.05711 9.60948C6.80706 9.35943 6.66659 9.02029 6.66659 8.66667C6.66659 8.31304 6.80706 7.97391 7.05711 7.72386Z"
        fill="#222730"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.76979 2.23641C6.06003 2.08117 6.3841 1.99997 6.71325 2H9.28649C9.61564 1.99997 9.93981 2.08117 10.2301 2.23641C10.5203 2.39166 10.7678 2.61617 10.9505 2.89L10.9509 2.89062L11.4918 3.70333C11.5527 3.79446 11.6353 3.86951 11.732 3.9212C11.8287 3.97295 11.9368 4.00002 12.0465 4H12.6666C13.197 4 13.7057 4.21071 14.0808 4.58579C14.4559 4.96086 14.6666 5.46957 14.6666 6V12C14.6666 12.5304 14.4559 13.0391 14.0808 13.4142C13.7057 13.7893 13.197 14 12.6666 14H3.33325C2.80282 14 2.29411 13.7893 1.91904 13.4142C1.54397 13.0391 1.33325 12.5304 1.33325 12V6C1.33325 5.46957 1.54397 4.96086 1.91904 4.58579C2.29411 4.21071 2.80282 4 3.33325 4H3.95325C4.06298 4.00002 4.17112 3.97295 4.26787 3.9212C4.36454 3.8695 4.44696 3.79476 4.50784 3.7036L5.04935 2.89C5.23205 2.61617 5.47951 2.39166 5.76979 2.23641ZM6.39863 3.41214C6.49539 3.36039 6.60342 3.33332 6.71315 3.33333H9.28659C9.39631 3.33332 9.50445 3.36039 9.60121 3.41214C9.69785 3.46383 9.78025 3.53854 9.84113 3.62967L10.3827 4.44333C10.5654 4.71717 10.8128 4.94167 11.1031 5.09692C11.3934 5.25216 11.7174 5.33337 12.0466 5.33333H12.6666C12.8434 5.33333 13.013 5.40357 13.138 5.5286C13.263 5.65362 13.3333 5.82319 13.3333 6V12C13.3333 12.1768 13.263 12.3464 13.138 12.4714C13.013 12.5964 12.8434 12.6667 12.6666 12.6667H3.33325C3.15644 12.6667 2.98687 12.5964 2.86185 12.4714C2.73682 12.3464 2.66659 12.1768 2.66659 12V6C2.66659 5.82319 2.73682 5.65362 2.86185 5.5286C2.98687 5.40357 3.15644 5.33333 3.33325 5.33333H3.95315C4.2823 5.33337 4.60647 5.25216 4.89672 5.09692C5.18699 4.94167 5.43445 4.71717 5.61715 4.44333L6.15849 3.63C6.21937 3.53881 6.30194 3.46385 6.39863 3.41214Z"
        fill="#222730"
      />
    </mask>
    <g mask="url(#mask0_5642_5025)">
      <rect width="16" height="16" fill="#000E18" fillOpacity="0.593" />
    </g>
  </svg>
)
