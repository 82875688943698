import { build, fake, oneOf } from '@jackfranklin/test-data-bot'
import snakecaseKeys from 'snakecase-keys'

import { type ContractItem } from '~/domains/credit/revolving-credit/follow-up/entities'

const contractBuilderInCamelCase = build<ContractItem>('Contract', {
  fields: {
    id: fake(f => f.datatype.uuid().slice(0, 20)),
    number: fake(f => f.finance.account(4)),
    originId: fake(f => f.datatype.uuid().slice(0, 20)),
    contractType: oneOf('REVOLVING_CREDIT_ACCEPTANCE_TERM'),
    url: fake(f => `https://credit-sandbox.stone.com.br/contracts-service/api/v1/contracts/${f.datatype.uuid()}`),
    dueDate: fake(f => f.date.future()),
    signatureDeadline: fake(f => f.date.future().toISOString().split('T')[0]),
    customer: {
      id: fake(f => f.datatype.uuid()),
      name: fake(f => f.name.findName()),
      document_type: oneOf('CPF', 'CNPJ'),
      document_number: fake(f => f.finance.account(14)),
      email: fake(f => f.internet.email()),
      stoneAccount: {
        bankName: 'Stone Pagamentos S.A.',
        bankNumber: '197',
        branchNumber: fake(f => f.finance.account(4)),
        branchDigit: '',
        number: fake(f => f.finance.account(6)),
        digit: '',
        type: 'Payment'
      },
      address: {
        postalCode: fake(f => f.address.zipCode()),
        state: fake(f => f.address.stateAbbr()),
        city: fake(f => f.address.city()),
        complement: fake(f => f.address.secondaryAddress()),
        street: fake(f => f.address.streetName()),
        number: fake(f => f.random.alphaNumeric(3)),
        neighborhood: fake(f => f.address.citySuffix())
      }
    },
    status: oneOf('Created'),
    creationDate: fake(f => f.date.past().toISOString()),
    revolvingCreditAcceptanceTerm: {
      totalCreditLimitAmount: fake(f => f.finance.amount(1000, 100000, 2)),
      retentionRate: fake(f => f.finance.amount(0.1, 0.9, 2))
    },
    involvedParties: [
      {
        id: fake(f => f.datatype.uuid()),
        name: fake(f => f.name.findName()),
        documentType: oneOf('CPF', 'CNPJ'),
        documentNumber: fake(f => f.finance.account(14)),
        email: fake(f => f.internet.email()),
        stoneAccount: {
          bankName: 'Stone Pagamentos S.A.',
          bankNumber: '197',
          branchNumber: fake(f => f.finance.account(4)),
          branchDigit: '',
          number: fake(f => f.finance.account(6)),
          digit: '',
          type: 'Payment'
        },
        address: {
          postalCode: fake(f => f.address.zipCode()),
          state: fake(f => f.address.stateAbbr()),
          city: fake(f => f.address.city()),
          complement: fake(f => f.address.secondaryAddress()),
          street: fake(f => f.address.streetName()),
          number: fake(f => f.random.alphaNumeric(3)),
          neighborhood: fake(f => f.address.citySuffix())
        }
      }
    ],
    resource: {
      resourceType: 'Revolving Credit',
      name: 'Revolving Credit'
    }
  }
})()

const contractBuilder = () => snakecaseKeys({ ...contractBuilderInCamelCase })

const contractsSuccess = {
  items: [contractBuilder()],
  paging: {
    total_items: 1,
    current_page: 1,
    page_size: 100,
    page_count: 1
  }
}

export { contractBuilder, contractsSuccess }
