import dayjs from 'dayjs'

import { type ActivationStepIndexes } from '~/lib/types'

export function stepsProgress(step: ActivationStepIndexes, deadline: string) {
  const stepsCanceled = [
    {
      title: 'Pedido de visita recebido',
      completed: true
    },
    {
      title: 'Visita técnica prevista',
      completed: true
    },
    {
      title: 'Nosso time está a caminho',
      completed: true
    },
    {
      title: 'Pedido cancelado'
    }
  ]

  const stepsInProgress = [
    {
      title: 'Pedido de visita recebido',
      completed: step > 0
    },
    {
      title: 'Visita técnica prevista',
      completed: step > 1
    },
    {
      title: 'Nosso time está a caminho',
      completed: step > 2
    },
    {
      title: 'Pedido concluído',
      completed: step > 3
    }
  ]

  const stepsInProgressTreatment = [
    {
      title: 'Pedido de visita recebido',
      completed: step > 0
    },
    {
      title: 'Visita técnica prevista',
      completed: step > 1
    },
    {
      title: 'Confirmação de dados',
      description: 'Vamos entrar em contato pra confirmar seus dados antes da visita técnica da Stone.',
      completed: step > 2
    },
    {
      title: 'Nosso time está a caminho',
      completed: step > 3
    },
    {
      title: 'Pedido concluído',
      completed: step > 4
    }
  ]

  const stepsInProgressRepique = [
    {
      title: 'Pedido de visita recebido',
      completed: step > 0
    },
    {
      title: 'Visita técnica prevista',
      completed: step > 1
    },
    {
      title: 'Nosso time está a caminho',
      completed: step > 2
    },
    {
      title: 'Vamos tentar fazer uma nova visita técnica',
      description:
        'A nova tentativa vai ser feita no mesmo endereço em ' + dayjs(deadline).format('DD [de] MMMM [de] YYYY'),
      completed: step > 3
    },
    {
      title: 'Nosso time está a caminho',
      completed: step > 4
    },
    {
      title: 'Pedido concluído',
      completed: step > 5
    }
  ]
  return [stepsCanceled, stepsInProgress, stepsInProgressTreatment, stepsInProgressRepique]
}
