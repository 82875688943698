export const topupProviderNameDictionary: Record<string, string> = {
  '2087': 'Claro',
  '2092': 'Claro Fixo',
  '2089': 'Oi',
  '2117': 'Oi Fixo',
  '2093': 'CTBC CEL',
  '2091': 'Algar Fixo',
  '2130': 'Correios',
  '2086': 'Tim',
  '2090': 'Embratel',
  '2098': 'Nextel',
  '2120': 'Sercomtel',
  '2088': 'Vivo'
}

export const getTopupProviderName = (providerId?: string) =>
  providerId ? topupProviderNameDictionary[providerId] : 'Desconhecido'
