import { analiticaInstance } from 'shared/analitica'

export default analiticaInstance.eventFactory('Hidden Sensitive Toggle', {
  HiddenSensitiveToggle: {
    /**
     * @analitica When the user click on toggle
     */
    click: (isHiddenSensitive: boolean) => ({
      label: 'home web - account balance clicked',
      metadata: {
        content: !isHiddenSensitive ? 'hidden' : 'visible'
      }
    })
  }
})
