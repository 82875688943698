import { stringify } from 'qs'
import { z } from 'zod'

import { Endpoints } from '../endpoints'
import { httpClient } from '../http-client'

import { type SwhResponse } from './types'
const ITEMS_PER_PAGE = 25

export namespace ProductCatalog {
  export enum ProductStatus {
    All = 'all',
    Draft = 'draft',
    Archived = 'archived',
    Public = 'public'
  }

  export type RequestQueryString = Partial<{
    categoryId: string[]
    categoryName?: string
    cursor: string | null
    productName: string
    orderBy: string
    orderDirection: string
    pageSize: number
    status: string[]
    subcategoryId: string
    subcategoryName?: string
    withoutSubcategory: boolean
    gtin?: string
  }>

  export type ProductImage = {
    ref: string
    url: string
    context: string
    altText: string
  }

  export type Item = {
    id: string
    name: string
    categoryId: string
    categoryName: string
    subCategoryId: string
    subCategoryName: string
    variants: Array<{
      sku: string
      variationsOptions: Array<{
        id: string
        value: string
        type: string
      }>
      price: { value: number | null }
      currency: string
      barcode: string
      stock: { actual: number }
      images: Array<{ url: string; altText: string }>
    }>
    taxation: {
      productOrigin: string
      ncm: string
      cest: string
      pisCofins: string
      taxConfiguration: string
    }
    status: string
    description: string
    images: ProductImage[]
  }

  export type ItemsResponse = SwhResponse<{
    id: string
    name: string
    description: string
    category: {
      id: string
      name: string
    }
    subcategory: {
      id: string
      name: string
    }
    status: ProductStatus
    variants: Array<{
      sku: string | null
      gtin: string | null
      name: string
      price: { sale: number | null }
      stock: { current: number }
    }>
  }>

  type VariationTypes = {
    id: string
    name: string
    existentVariationOptions: Array<{ id: string; name: string }>
  }

  type ProductVariant = {
    id: string
    sku: string | null
    gtin: string | null
    name: string
    price: { sale: number }
    stock: { current: number }
    chosenVariationOptions?: Array<{
      id: string
      name: string
      option: { id: string; name: string }
    }>
    tax: {
      ncm: string
      cfop: string
      cest: string
      csosn: string
      quoteIcms: string
      quoteIcmsSt: string
      quoteIcmsDif: string
      reduceIcms: string
      reduceIcmsSt: string
      cBenef: string
      legalIcms: string
      cstIpi: string
      quoteIpi: string
      codeIpi: string
      legalIpi: string
      cstPis: string
      quotePis: string
      cstCofins: string
      quoteCofins: string
    }
  }

  export type GetProductById = {
    id: string
    name: string
    description: string
    status: ProductStatus
    category: { id: string; name: string }
    subcategory?: { id: string; name: string }
    variationsTypes?: VariationTypes[]
    variants: ProductVariant[]
  }

  export const get = async (storeId: string, params?: RequestQueryString): Promise<ItemsResponse> => {
    try {
      if (params?.productName) {
        Object.assign(params, {
          categoryName: params.productName,
          subcategoryName: params.productName,
          gtin: params.productName
        })
      }
      const response = await httpClient.get<ItemsResponse>(Endpoints.products.all(storeId), {
        params: { ...params, pageSize: ITEMS_PER_PAGE },
        paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' })
      })
      return response.data
    } catch (error: any) {
      console.error('Erro ao buscar um produto', error)
      throw error.response || error
    }
  }

  export const getById = async (storeId: string, productId: string): Promise<GetProductById> => {
    const response = await httpClient.get<GetProductById>(Endpoints.products.id(storeId, productId))
    return response.data
  }

  const taxSchema = z.string()

  const tax = z.object({
    cBenef: taxSchema,
    cest: taxSchema,
    cfop: taxSchema,
    codeIpi: taxSchema,
    csosn: taxSchema,
    cstCofins: taxSchema,
    cstIpi: taxSchema,
    cstPis: taxSchema,
    legalIcms: taxSchema,
    legalIpi: taxSchema,
    ncm: taxSchema,
    quoteCofins: taxSchema,
    quoteIcms: taxSchema,
    quoteIcmsDif: taxSchema,
    quoteIcmsSt: taxSchema,
    quoteIpi: taxSchema,
    quotePis: taxSchema,
    reduceIcms: taxSchema,
    reduceIcmsSt: taxSchema
  })

  const variationItem = z.object({
    tax,
    sku: z.string().nullable(),
    gtin: z.string().nullable(),
    stock: z.object({ current: z.number().int() }),
    price: z.object({ sale: z.number().transform(x => Math.ceil(x * 100)) }),
    variationsOptions: z.array(z.object({ variationTypeId: z.string(), variationOptionId: z.string() }))
  })

  export const createSchema = z.object({
    categoryId: z.string(),
    description: z.string(),
    name: z.string(),
    status: z.string(),
    subcategoryId: z.string().optional(),
    variants: z.array(variationItem),
    variationTypeIds: z.array(z.string())
  })

  export type CreateProduct = z.infer<typeof createSchema>

  export type VariationItem = z.infer<typeof variationItem>

  export type Image = { ref: string }

  type CreateProductResponse = { id: string }

  export const create = async (storeId: string, product: CreateProduct): Promise<CreateProductResponse> => {
    const response = await httpClient.post<CreateProductResponse>(Endpoints.products.all(storeId), product)
    return response.data
  }

  export const updateStatus = async (storeId: string, productId: string, status: ProductStatus) => {
    const response = await httpClient.put<CreateProduct>(Endpoints.products.status(storeId, productId), { status })
    return response.data
  }
}
