import { isomorphicCookies } from '~/lib/helpers/utils/cookies'

import { type CheckType, type IdentityScopes, type IsIdentityOwner } from './types'

function _getSubjetScopes() {
  const { identityId } = isomorphicCookies.getAll()
  const identityScopes = window.sessionStorage.getItem('identityScopes')

  if (!identityId) return []

  const { scopes } = (identityScopes ? JSON.parse(identityScopes) : []) as IdentityScopes

  return scopes
}

function findScopes(key: string) {
  return (scopes: string[]) => scopes?.filter(scope => scope === key).length
}

function verifyScopes(scopes: string[], checkType: CheckType = 'all') {
  if (scopes.length === 0) return false

  const subjectScopes = _getSubjetScopes()

  const hasScope = (key: string) => findScopes(key)(subjectScopes)
  const doesNotHaveScope = (key: string) => !findScopes(key)(subjectScopes)

  const statusTypeMap: Record<CheckType, boolean> = {
    all: scopes?.every(hasScope),
    notHave: scopes?.every(doesNotHaveScope),
    some: scopes?.some(hasScope)
  }

  return statusTypeMap[checkType]
}

function verifyIdentityOwnership() {
  const { identityId } = isomorphicCookies.getAll()
  const isIdentityOwner = window.sessionStorage.getItem('isIdentityOwner')

  if (!identityId) return false

  const { isOwner } = (isIdentityOwner ? JSON.parse(isIdentityOwner) : []) as IsIdentityOwner

  return Boolean(isOwner)
}

export const NightOwl = { verifyScopes, verifyIdentityOwnership }
