import { type IconProps, Badge, Divider, Icon, ListItem } from '@stone-payments/jade'
import jadeTheme from '@stone-payments/jade-design-tokens/cjs/stone'

import { Box } from '~/domains/platform/design-system'
import { type Entity } from '~/domains/platform/infra/deus-ex-machina/types'
import { profileMenuConfig } from '~/domains/platform/layout/profile-menu/menu-config'
import BlackBird from '~/domains/platform/lib/blackbird'
import { For, If } from '~/domains/platform/lib/utilities-components'

import analitica from '../analitica'

const icons = [
  'person',
  'file-folded',
  'percent-box',
  'lock-close',
  'notebook',
  'circle-money',
  'device-phone',
  'bank',
  'circle-help',
  'chart-arrow-up'
] as Array<IconProps['use']>

export function ProfileMenu({ entity }: { entity: Entity }) {
  const profileMenuItems = profileMenuConfig(entity)
  const query = BlackBird.getQuery()
  const route = BlackBird.getPathname()

  const handleBack = () => {
    analitica.events.profileMenu.click()
    BlackBird.goHome()
  }

  const updatedItems = profileMenuItems.map((item, index) => {
    return {
      ...item,
      icon: icons[index]
    }
  })

  return (
    <>
      <Box py={jadeTheme.global.space[50]}>
        <ListItem
          content={<ListItem.Content label="Voltar" />}
          leading={<Icon size="medium" use="arrow-left" />}
          onClick={handleBack}
        />
      </Box>
      <Divider />
      <Box py={jadeTheme.global.space[100]}>
        <For
          of={updatedItems}
          render={(item, index) => {
            const { active, icon, isNew, label, onClick, order, path, show } = item

            const activeRoute = active && active(route)

            return (
              <If key={index} condition={Boolean(show)}>
                <ListItem
                  key={order}
                  role={`menu-item-${icon}`}
                  compact
                  leading={
                    <Icon
                      role={`menu-item-icon-${icon}`}
                      size="medium"
                      color={activeRoute ? 'brand' : 'medium'}
                      use={icon}
                    />
                  }
                  content={<ListItem.Content label={label} clampLine={['label']} />}
                  onClick={() => {
                    onClick && onClick()
                    path && BlackBird.travelTo({ pathname: path, ...query })
                  }}
                  variant={activeRoute ? 'active' : 'neutral'}
                  trailing={
                    <If condition={Boolean(isNew)}>
                      <Badge label="Novo" variant="text" voice="info" />
                    </If>
                  }
                />
              </If>
            )
          }}
        />
      </Box>
    </>
  )
}
