import { ProductCatalog } from 'swh/shared/http'
import { currencyToNumber } from 'swh/shared/utils'
import { z } from 'zod'

export namespace Actions {
  type ProductSchemaArgs = { hasGtin: boolean; hasSku: boolean }

  const taxNumber = z.coerce
    .string()
    .min(1, 'Campo obrigatório')
    .regex(/[0-9,.]+/g, 'Somente valor numérico')
    .refine(
      val => {
        const parsedValue = parseFloat(val)
        return parsedValue && parsedValue >= 0
      },
      { message: 'Valor numérico deve ser maior ou igual a 0' }
    )
    .transform(x => x || '0')

  const optionalStr = z.coerce.string().optional().default('')

  const tax = () =>
    z.object({
      ncm: taxNumber,
      cfop: z.coerce
        .string()
        .length(4, 'Deve possuir 4 caracteres')
        .regex(/[0-9,.]+/g, 'Somente valor numérico')
        .transform(x => x || ''),
      cest: taxNumber.transform(x => x.replace(/[.,]/g, '')),
      csosn: taxNumber,
      quoteIcms: taxNumber,
      quoteIcmsSt: taxNumber,
      quoteIcmsDif: taxNumber,
      reduceIcms: taxNumber,
      reduceIcmsSt: taxNumber,
      cBenef: optionalStr,
      legalIcms: optionalStr,
      cstIpi: optionalStr,
      quoteIpi: taxNumber,
      codeIpi: taxNumber,
      legalIpi: taxNumber,
      cstPis: taxNumber,
      quotePis: taxNumber,
      cstCofins: taxNumber,
      quoteCofins: taxNumber
    })

  const variantOption = (args: ProductSchemaArgs) =>
    z.object({
      entityId: z.string(),
      id: z.string(),
      name: z.string(),
      tax: tax(),
      gtin: args.hasGtin ? z.string().min(1, 'Código de barras deve ser preenchido') : z.string().nullable(),
      sku: args.hasSku ? z.string().min(1, 'O SKU deve ser preenchido') : z.string().nullable(),
      variationTypes: z.array(z.string()),
      stock: z.object({
        current: z.coerce.string().min(1, 'O estoque não pode estar vazio').transform(Number)
      }),
      price: z.object({
        sale: z.coerce.string().min(1, 'Preço de venda não pode estar vazio').transform(currencyToNumber)
      }),
      variationsOptions: z.array(z.object({ id: z.string(), name: z.string(), parentId: z.string() }))
    })

  export type Variant = z.infer<ReturnType<typeof variantOption>>

  export const defaultTax = {
    ncm: '',
    cfop: '',
    cest: '',
    csosn: '',
    quoteIcms: '',
    quoteIcmsSt: '',
    quoteIcmsDif: '',
    reduceIcms: '',
    reduceIcmsSt: '',
    cBenef: '',
    legalIcms: '',
    cstIpi: '',
    quoteIpi: '',
    codeIpi: '',
    legalIpi: '',
    cstPis: '',
    quotePis: '',
    cstCofins: '',
    quoteCofins: ''
  }

  export const createVariant = (): Variant => ({
    entityId: '',
    id: '',
    name: '',
    sku: '',
    gtin: '',
    variationTypes: [],
    price: { sale: 0 },
    stock: { current: 0 },
    variationsOptions: [],
    tax: defaultTax
  })

  export const productSchema = (args: ProductSchemaArgs) =>
    z.object({
      name: z.string().min(1, 'Nome do produto não pode estar vazio'),
      status: z.nativeEnum(ProductCatalog.ProductStatus),
      description: z.string(),
      categoryId: z.string().min(1, 'Selecione uma categoria'),
      subCategoryId: z.string().optional().nullable().default(''),
      images: z.array(z.string()),
      hasVariants: z.boolean(),
      gtin: z.boolean(),
      sku: z.boolean(),
      variants: z.array(variantOption(args)).default([])
    })

  export type PostProduct = z.infer<ReturnType<typeof productSchema>>

  export const request = {
    updateVariant: z.object({ name: z.string() }),
    createVariant: z.object({
      name: z.string(),
      items: z.array(z.object({ value: z.string() }))
    })
  }

  export const parse = (product: PostProduct): ProductCatalog.CreateProduct => {
    const variants = product.variants.map((variant): ProductCatalog.VariationItem => {
      return {
        tax: variant.tax,
        sku: variant.sku || null,
        gtin: variant.gtin || null,
        price: { sale: Math.ceil(variant.price.sale * 100) },
        stock: { current: variant.stock.current },
        variationsOptions: variant.variationsOptions.map(x => ({
          variationTypeId: x.parentId,
          variationOptionId: x.id
        }))
      }
    })
    const flat = variants.flatMap(x => x.variationsOptions.flatMap(y => y.variationTypeId))
    const variationTypeIds = Array.from(new Set(flat))
    return {
      variants,
      variationTypeIds,
      name: product.name,
      status: product.status,
      categoryId: product.categoryId,
      description: product.description,
      subcategoryId: product.subCategoryId || undefined
    }
  }
}
