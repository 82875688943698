import { useCallback, useEffect, useState } from 'react'
import {
  type DropdownSelectionOption,
  type DropdownSelectionProps,
  type DropdownSelectionType,
  DropdownSelection as DropdownSelectionJade
} from '@stone-payments/jade'

function alternateOption(options: DropdownSelectionOption[], items: DropdownSelectionOption[]) {
  const values = items.map(item => item.value)

  return options.map(option => {
    return { ...option, selected: values.includes(option.value) }
  })
}

export function DropdownSelection<Type extends DropdownSelectionType = DropdownSelectionType>({
  type,
  title,
  trigger,
  options: initialOptions,
  onApply
}: DropdownSelectionProps<Type>) {
  const [options, setOptions] = useState<DropdownSelectionOption[]>([])
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    setOptions(initialOptions)
  }, [initialOptions, open])

  const handleOnApply = () => {
    onApply?.(options.filter(item => item.selected))
  }

  const handleOnOptionChange = (items: DropdownSelectionOption[]) => {
    setOptions(alternateOption(options, items))
  }

  const toggleOpen = useCallback(() => setOpen(prev => !prev), [])

  return (
    <DropdownSelectionJade
      key={+new Date()}
      open={open}
      onApply={handleOnApply}
      toggleOpen={toggleOpen}
      onOptionChange={handleOnOptionChange}
      options={options}
      title={title}
      trigger={trigger}
      type={type}
    />
  )
}
