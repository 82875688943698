import {
  type ActiveMockFlag,
  type MockFlagMap,
  type MockFlagsStorageProps,
  type TempMock
} from '../types/mock-flags-types'

export const STORAGE_KEY = 'mockFlags'
export const STORAGE_KEY_TEMP = 'tempMockFlags'

export const MockFlagsStorage = {
  set({ identifiers, mockFlags }: MockFlagsStorageProps) {
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify({ identifiers, mockFlags }))
  },
  get(): MockFlagMap {
    const mockFlags = sessionStorage.getItem(STORAGE_KEY)

    return mockFlags ? JSON.parse(mockFlags) : { mockFlags: [], identifiers: [] }
  },
  clear() {
    sessionStorage.removeItem(STORAGE_KEY)
  },
  update({ name, currentScenario }: ActiveMockFlag) {
    const mockFlags = sessionStorage.getItem(STORAGE_KEY)
    const flags = mockFlags ? JSON.parse(mockFlags) : { mockFlags: [], identifiers: [] }

    if (flags.mockFlags.length > 0) {
      const activeMocks: ActiveMockFlag[] = flags.mockFlags
      const mocksToUpdate = activeMocks.map(mock => {
        if (mock.name === name) {
          return {
            name: mock.name,
            currentScenario
          }
        }
        return mock
      })
      sessionStorage.setItem(STORAGE_KEY, JSON.stringify({ identifiers: flags.identifiers, mockFlags: mocksToUpdate }))
    }

    return
  }
}

export const TempMockFlagsStorage = {
  set(tempMocks: TempMock[]) {
    sessionStorage.setItem(STORAGE_KEY_TEMP, JSON.stringify(tempMocks))
  },
  get(): TempMock[] {
    const tempMocks = sessionStorage.getItem(STORAGE_KEY_TEMP)

    return tempMocks !== 'undefined' && tempMocks !== null ? JSON.parse(tempMocks) : []
  },
  clear() {
    sessionStorage.removeItem(STORAGE_KEY_TEMP)
  }
}
