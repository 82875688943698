// @ts-nocheck
import { type ChangeEvent, Fragment, useRef, useState } from 'react'
import { Alert, Button, Drawer, Icon, InputField, Select, Text } from '@stone-payments/jade'
import equal from 'lodash.isequal'
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Actions } from 'swh/catalog/products-upsert'
import { type ErrorProperties, useErrors } from 'swh/shared/components'
import { getLeaf, getPath, getSchemaShape, plurals, setPath } from 'swh/shared/utils'
import TheMaskInput from 'the-mask-input'

import { PageCard } from '../page-swh-container'

import { type UpsertMode } from './variants-form'

type ProductRowProps = {
  calcGrids: number
  variant: Actions.Variant
  index: number
  gtin: boolean
  hasVariants: boolean
  sku: boolean
  mode: UpsertMode
}

// eslint-disable-next-line @typescript-eslint/ban-types
type K = keyof Actions.Variant | (string & {})

const createMask = (regex: RegExp, maxLength: number | null) => (s: string) => {
  if (maxLength === null) {
    return Array.from<RegExp>({ length: s.length }).fill(regex)
  }
  return maxLength > s.length
    ? Array.from<RegExp>({ length: s.length }).fill(regex)
    : Array.from<RegExp>({ length: maxLength }).fill(regex)
}

const createSkuMask = createMask(/[a-zA-Z0-9]/, null)

const createGtinMask = createMask(/[0-9 ]/, 14)

const createCestMask = createMask(/[0-9.]/, 13)

const DEFAULTS: Partial<Actions.Variant['tax']> = {
  codeIpi: '01',
  cstPis: '01',
  cstCofins: '49',
  cstIpi: '',
  cfop: '5102',
  ncm: '85167920',
  legalIcms: ''
}

const cstOptions = [
  { value: '102', label: '102 - Tributação pelo Simples Nacional sem permissão de crédito' },
  { value: '103', label: '103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta' },
  { value: '300', label: '300 - Imune' },
  { value: '400', label: '400 - Não tributada pelo Simples Nacional (v2.0)' },
  {
    value: '500',
    label: '500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação (v2.0)'
  },
  { value: '900', label: '900 - Outros (v2.0)' }
]

const ProductRow = (props: ProductRowProps) => {
  const variant = props.variant
  const initialState = useRef({ ...variant, tax: { ...variant?.tax, ...DEFAULTS } })
  const [errors, setErrors] = useErrors()
  const [open, setOpen] = useState(false)
  const [state, setState] = useState<Actions.Variant>(initialState.current)
  const edited = !equal(state.tax, initialState.current.tax)
  const addButtonLabel = props.mode === 'edit' || edited ? 'Editar' : 'Preencher'

  const field = (suffix: K) => suffix

  const errorField = (suffix: K) => {
    if (errors === null) return null
    const message = getPath(errors, `variants[${props.index}].${suffix}`)
    return Array.isArray(message) ? message[0] : message
  }

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    const pathTo = `variants[${props.index}].${name}`
    setState(prev => setPath(prev, name, value))
    if (value !== '') {
      const schema = getSchemaShape(pathTo, Actions.productSchema({ hasGtin: true, hasSku: true }))
      if (schema) {
        const validation = schema.safeParse(value)
        if (!validation.success) {
          return setErrors(prev => setPath(prev || {}, pathTo, validation.error?.issues[0].message))
        }
        return setErrors(prev => (prev === null ? null : setPath(prev || {}, pathTo, undefined)))
      }
    }
  }

  const gtinError = errorField('gtin')

  const tax = {
    cfopError: errorField('tax.cfop'),
    cBenefError: errorField('tax.cBenef'),
    cestError: errorField('tax.cest'),
    codeIpiError: errorField('tax.codeIpi'),
    csosnError: errorField('tax.csosn'),
    cstCofinsError: errorField('tax.cstCofins'),
    cstIpiError: errorField('tax.cstIpi'),
    cstPisError: errorField('tax.cstPis'),
    legalIcmsError: errorField('tax.legalIcms'),
    legalIpiError: errorField('tax.legalIpi'),
    ncmError: errorField('tax.ncm'),
    quoteCofinsError: errorField('tax.quoteCofins'),
    quoteIcmsDifError: errorField('tax.quoteIcmsDif'),
    quoteIcmsError: errorField('tax.quoteIcms'),
    quoteIcmsStError: errorField('tax.quoteIcmsSt'),
    quoteIpiError: errorField('tax.quoteIpi'),
    quotePisError: errorField('tax.quotePis'),
    reduceIcmsError: errorField('tax.reduceIcms'),
    reduceIcmsStError: errorField('tax.reduceIcmsSt'),
    saleError: errorField('price.sale'),
    skuError: errorField('sku'),
    stockError: errorField('stock.current')
  }

  const hasTaxErrors = Object.values(tax).some(x => !!x)

  return (
    <div
      className="grid gap-4 items-center"
      style={{ gridTemplateColumns: `repeat(${props.calcGrids}, minmax(0, 1fr))` }}
    >
      {props.hasVariants ? <span>{variant.name}</span> : null}
      {props.sku ? (
        <span>
          <InputField
            as={TheMaskInput}
            mask={createSkuMask}
            label=""
            name={field('sku')}
            error={!!tax.skuError}
            supportText={tax.skuError}
            onChange={onChange}
            placeholder="STN001..."
            required={props.sku}
            value={state.sku}
          />
        </span>
      ) : null}
      {props.gtin ? (
        <span>
          <InputField
            label=""
            as={TheMaskInput}
            mask={createGtinMask}
            name={field('gtin')}
            error={!!gtinError}
            supportText={gtinError}
            onChange={onChange}
            placeholder="78901..."
            required={props.gtin}
            value={state.gtin}
          />
        </span>
      ) : null}
      <span>
        <InputField
          value={state.price.sale === 0 ? '' : state.price.sale.toString()}
          onChange={onChange}
          name={field('price.sale')}
          error={!!tax.saleError}
          supportText={tax.saleError}
          label=""
          mask="money"
          as={TheMaskInput}
          placeholder="00,00"
        />
      </span>
      <span>
        <InputField
          value={state.stock.current === 0 ? '' : state.stock.current.toString()}
          onChange={onChange}
          name={field('stock.current')}
          error={!!tax.stockError}
          supportText={tax.stockError}
          label=""
          mask="int"
          as={TheMaskInput}
          placeholder="00"
        />
      </span>
      <span>
        <span className="flex justify-center items-center w-full">
          <input type="hidden" value={JSON.stringify(state)} name={`variants.items[${props.index}]`} />
          <Button
            className="flex items-center"
            onClick={() => setOpen(true)}
            variant={hasTaxErrors ? 'danger-ghost' : 'primary-ghost'}
          >
            {addButtonLabel}
          </Button>
          <button
            type="button"
            onClick={() => setOpen(true)}
            className="m-0 p-0 appearance-none bg-transparent border-0 mt-2 cursor-pointer text-center"
          >
            <Icon size="small" use="chevron-right" className="pt-2" />
          </button>
        </span>
        <Drawer backdrop toggle={setOpen} title={variant.name} open={open}>
          <div className="p-jade-250 space-y-jade-200">
            {props.hasVariants ? (
              <div className="grid empty:hidden grid-cols-1 gap-jade-200 items-baseline">
                {props.sku || props.gtin ? (
                  <Text variant="overline" className="uppercase">
                    Dados da variante
                  </Text>
                ) : null}
                {variant.name ? <InputField readOnly disabled label="Variante" defaultValue={variant.name} /> : null}
                {props.sku ? (
                  <InputField
                    as={TheMaskInput}
                    mask={createSkuMask}
                    label="SKU"
                    name={field('sku')}
                    error={!!tax.skuError}
                    supportText={tax.skuError}
                    onChange={onChange}
                    placeholder="STN001..."
                    required={props.sku}
                    value={state.sku ?? ''}
                  />
                ) : null}
                {props.gtin ? (
                  <InputField
                    as={TheMaskInput}
                    mask={createGtinMask}
                    label="Código de barras"
                    name={field('gtin')}
                    error={!!gtinError}
                    supportText={gtinError}
                    onChange={onChange}
                    placeholder="78901..."
                    required={props.gtin}
                    value={state.gtin}
                  />
                ) : null}
              </div>
            ) : null}
            {props.hasVariants ? (
              <Fragment>
                <div className="border-solid border-transparent border-b border-b-jade-border-low w-full block font-medium">
                  <Text variant="overline" className="uppercase">
                    Tributação da variante
                  </Text>
                </div>
                <p className="text-left">
                  Preencha os dados de tributação do produto para que seja possível emitir a nota fiscal de sua venda.
                </p>
              </Fragment>
            ) : null}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-jade-200 items-end">
              <div className="w-full col-span-2">
                <InputField
                  type="number"
                  onChange={onChange}
                  value={state.tax.ncm}
                  name={field('tax.ncm')}
                  error={!!tax.ncmError}
                  supportText={tax.ncmError}
                  label="Cód. NCM"
                  placeholder="0"
                />
              </div>
              <div className="w-full col-span-2">
                <InputField
                  onChange={onChange}
                  value={state.tax.cest}
                  as={TheMaskInput}
                  mask={createCestMask}
                  name={field('tax.cest')}
                  error={!!tax.cestError}
                  supportText={tax.cestError}
                  label="Cód. CEST"
                  placeholder="0"
                />
              </div>
              <div className="w-full col-span-2">
                <Select
                  error={!!tax.csosnError}
                  label="CST ICMS (CSOSN)"
                  name={field('tax.csosn')}
                  onChange={onChange}
                  options={cstOptions}
                  placeholder="Escolha uma opção"
                  supportText={tax.csosnError}
                  value={state.tax.csosn}
                />
              </div>
              <InputField
                type="number"
                onChange={onChange}
                value={state.tax.cfop}
                name={field('tax.cfop')}
                error={!!tax.cfopError}
                supportText={tax.cfopError}
                label="Cód. CFOP"
                placeholder="0"
              />
              <InputField
                type="number"
                onChange={onChange}
                leadingItem={{ type: 'text', content: '%' }}
                value={state.tax.quoteIcms}
                name={field('tax.quoteIcms')}
                error={!!tax.quoteIcmsError}
                supportText={tax.quoteIcmsError}
                label="Alíq. ICMS"
                placeholder="0"
              />
              <InputField
                type="number"
                onChange={onChange}
                leadingItem={{ type: 'text', content: '%' }}
                value={state.tax.quoteIcmsSt}
                name={field('tax.quoteIcmsSt')}
                error={!!tax.quoteIcmsStError}
                supportText={tax.quoteIcmsStError}
                label="Alíq. ICMS ST"
                placeholder="0"
              />
              <InputField
                type="number"
                onChange={onChange}
                leadingItem={{ type: 'text', content: '%' }}
                value={state.tax.quoteIcmsDif}
                name={field('tax.quoteIcmsDif')}
                error={!!tax.quoteIcmsDifError}
                supportText={tax.quoteIcmsDifError}
                label="Alíq. Dif ICMS"
                placeholder="0"
              />
              <InputField
                type="number"
                onChange={onChange}
                leadingItem={{ type: 'text', content: '%' }}
                value={state.tax.reduceIcms}
                name={field('tax.reduceIcms')}
                error={!!tax.reduceIcmsError}
                supportText={tax.reduceIcmsError}
                label="Redução ICMS"
                placeholder="00"
              />
              <InputField
                type="number"
                onChange={onChange}
                leadingItem={{ type: 'text', content: '%' }}
                error={!!tax.reduceIcmsStError}
                supportText={tax.reduceIcmsStError}
                name={field('tax.reduceIcmsSt')}
                value={state.tax.reduceIcmsSt}
                label="Redução ICMS ST"
                placeholder="00"
              />
              <InputField
                onChange={onChange}
                value={state.tax.cBenef}
                name={field('tax.cBenef')}
                error={!!tax.cBenefError}
                supportText={tax.cBenefError}
                label="Benef. ICMS (CBenef)"
                placeholder="ES000000"
              />
              <InputField
                type="number"
                onChange={onChange}
                label="Amparo legal ICMS"
                placeholder="00"
                error={!!tax.legalIcmsError}
                supportText={tax.legalIcmsError}
                value={state.tax.legalIcms}
                name={field('tax.legalIcms')}
              />
              <InputField
                type="number"
                onChange={onChange}
                label="CST IPI"
                placeholder="000"
                error={!!tax.cstIpiError}
                supportText={tax.cstIpiError}
                value={state.tax.cstIpi}
                name={field('tax.cstIpi')}
              />
              <InputField
                type="number"
                onChange={onChange}
                leadingItem={{ type: 'text', content: '%' }}
                label="Alíquota IPI"
                placeholder="00"
                error={!!tax.quoteIpiError}
                supportText={tax.quoteIpiError}
                value={state.tax.quoteIpi}
                name={field('tax.quoteIpi')}
              />
              <InputField
                type="number"
                onChange={onChange}
                label="Código Enq. IPI"
                placeholder="000"
                error={!!tax.codeIpiError}
                supportText={tax.codeIpiError}
                value={state.tax.codeIpi}
                name={field('tax.codeIpi')}
              />
              <InputField
                type="number"
                onChange={onChange}
                label="Amparo legal IPI"
                placeholder="00"
                error={!!tax.legalIpiError}
                supportText={tax.legalIpiError}
                value={state.tax.legalIpi}
                name={field('tax.legalIpi')}
              />
              <InputField
                type="number"
                onChange={onChange}
                label="CST PIS"
                placeholder="0"
                error={!!tax.cstPisError}
                supportText={tax.cstPisError}
                value={state.tax.cstPis}
                name={field('tax.cstPis')}
              />
              <InputField
                type="number"
                onChange={onChange}
                leadingItem={{ type: 'text', content: '%' }}
                label="Alíquota PIS"
                placeholder="00"
                error={!!tax.quotePisError}
                supportText={tax.quotePisError}
                value={state.tax.quotePis}
                name={field('tax.quotePis')}
              />
              <InputField
                type="number"
                onChange={onChange}
                label="CST COFINS"
                placeholder="000"
                error={!!tax.cstCofinsError}
                supportText={tax.cstCofinsError}
                value={state.tax.cstCofins}
                name={field('tax.cstCofins')}
              />
              <InputField
                type="number"
                onChange={onChange}
                leadingItem={{ type: 'text', content: '%' }}
                label="Alíquota COFINS"
                placeholder="00"
                error={!!tax.quoteCofinsError}
                supportText={tax.quoteCofinsError}
                value={state.tax.quoteCofins}
                name={field('tax.quoteCofins')}
              />
            </div>
          </div>
        </Drawer>
      </span>
    </div>
  )
}

type Props = {
  apiErrors: ErrorProperties
  gtin: boolean
  hasVariants: boolean
  localErrors: ErrorProperties
  mode: UpsertMode
  sku: boolean
  variants: Actions.Variant[]
}

const mapErrorMessages = (errorMap: ErrorProperties, localErrors: any, apiErrors: any) => {
  const localVariantErrors: any[] = Array.isArray(localErrors?.variants) ? localErrors?.variants : []
  const apiVariantErrors: any[] = errorMap?.creatingVariant
    ? []
    : Array.isArray(apiErrors?.variants)
    ? (apiErrors?.variants as any[])
    : []
  const hasTaxErrors = localVariantErrors.some(x => !!getLeaf(x))
  const concatErrors = localVariantErrors.concat(apiVariantErrors)
  const hasErrors = concatErrors.some(x => !!getLeaf(x))
  const errorsList = concatErrors.reduce((acc, el): Set<string> => {
    const label = getLeaf(el)
    if (label) acc.add(label)
    return acc
  }, new Set<string>())
  return { hasErrors, hasTaxErrors, errors: concatErrors, messages: Array.from(errorsList) }
}

export const SwhProductForm = (props: Props) => {
  const [errorState] = useErrors()
  const state = mapErrorMessages(errorState, props.localErrors, props.apiErrors)
  const calcGrids = 3 + (props.hasVariants ? 1 : 0) + (props.sku ? 1 : 0) + (props.gtin ? 1 : 0)
  return (
    <PageCard>
      {props.hasVariants ? (
        <Text variant="overline">
          {plurals(props.variants.length, {
            0: 'NENHUMA VARIANTE GERADA',
            1: 'UMA VARIANTE GERADA',
            many: x => `${x} VARIANTES GERADAS`
          })}
        </Text>
      ) : null}
      {state.hasErrors ? (
        <Alert
          showIcon
          variant={'negative' as any}
          icon={'circle-error' as any}
          title="Não foi possível publicar seu produto"
          description={
            <Fragment>
              <p>Verifique os errors destacados</p>
              <ul className="empty:hidden list-inside space-y-2 mt-4">
                {state.messages.map((x, i) => {
                  const label = getLeaf(x)
                  return label ? <li key={`variant-${i + 1}-error`}>{label}</li> : null
                })}
                {state.hasTaxErrors ? <li>Erro na tributação</li> : null}
              </ul>
            </Fragment>
          }
        />
      ) : null}
      <div className="w-full flex flex-col gap-4 justify-center">
        <div className="grid gap-4" style={{ gridTemplateColumns: `repeat(${calcGrids}, minmax(0, 1fr))` }}>
          {props.hasVariants ? <span className="font-normal">Variante</span> : null}
          {props.sku ? <span className="font-normal">SKU</span> : null}
          {props.gtin ? <span className="font-normal">Cód. de barras</span> : null}
          <span data-sku={props.sku} data-gtin={props.gtin} className="font-normal">
            Preço de venda
          </span>
          <span data-gtin={props.gtin} data-sku={props.sku} className="font-normal">
            Estoque
          </span>
          <span className="font-normal pr-5 text-center">Tributação</span>
        </div>
        {props.variants.map((variant, index) => (
          <ProductRow
            calcGrids={calcGrids}
            mode={props.mode}
            key={variant.name}
            gtin={props.gtin}
            hasVariants={props.hasVariants}
            index={index}
            sku={props.sku}
            variant={variant}
          />
        ))}
      </div>
    </PageCard>
  )
}
