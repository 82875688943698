import {
  type AmplitudeAnalyticsOptions,
  amplitudeAnalyticsPlugin,
  analyticsSDKFactory
} from '@dlpco/stonks-web-analytics'
import { AMPLITUDE_API_KEY } from 'shared/envs'

import { isomorphicCookies } from '~/lib/helpers'

import packageInfo from '../../../../../package.json'
import { authenticator } from '../../core/authenticator/authenticator'

import { type EventName } from './types'

const { token } = isomorphicCookies.getAll()

const subjectId = token ? authenticator.parseToken({ token })['stone_subject_id'] : undefined

const apiKey = AMPLITUDE_API_KEY() as string

const amplitudeAnalyticsPluginInstance = amplitudeAnalyticsPlugin(apiKey)

/**
 * @info should not be used.
 * This method will be used in the future.
 */
const analyticsBase = analyticsSDKFactory<AmplitudeAnalyticsOptions, EventName>({
  provider: amplitudeAnalyticsPluginInstance,
  options: { userId: subjectId, appVersion: packageInfo.version }
})

const analytics = {
  /**
   * @deprecated Use Analitica instead, see Analitica Docs or call #web-platform Slack Channel
   * @see [Analitica Docs](https://github.com/stone-payments/analitica/blob/main/packages/analitica/README.md#configurando-os-eventos)
   */
  sendEvent: analyticsBase.sendEvent,
  /**
   * @deprecated Use Analitica instead, see Analitica Docs or call #web-platform Slack Channel
   * @see [Analitica Docs](https://github.com/stone-payments/analitica/blob/main/packages/analitica/README.md#atualizando-as-metadatas-globais-in-runtime)
   */
  update: analyticsBase.update
}

export { analytics, analyticsSDKFactory }
