import { analiticaInstance } from 'shared/analitica'

import { type Analytics } from '~/domains/home-v2/types'

export default analiticaInstance.eventFactory('Header', {
  dropDownMenu: {
    /**
     * @analitica When the user click on drop-down menu
     */
    click: (analytics: Analytics) => ({
      label: analytics?.eventName,
      metadata: {
        type: analytics.type,
        properties: analytics?.properties
      }
    })
  },
  header: {
    /**
     * @analitica calls when click on the stone logo that goes to the house
     */
    click: () => ({
      label: 'home web - logo stone home clicked'
    })
  }
})
