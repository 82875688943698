import { type ExperimentValue } from '@stone-payments/experiment/src/lib/types'

// TODO: [Web Platform Refactor] Fix this circular dependencie
// eslint-disable-next-line @nx/enforce-module-boundaries
import { experimentInstance } from 'shared/analitica'
// TODO: [Web Platform Refactor] Fix this circular dependencie
// eslint-disable-next-line @nx/enforce-module-boundaries
import { type UseQueryRequestOptions, useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

import { getAllExperiment } from './use-all-experiment'

export async function getExperimentFlags<T>(subjectId: string, experimentFlag: string, fallback?: string | T) {
  await getAllExperiment(subjectId)

  return await experimentInstance.variant<T>(experimentFlag, fallback)
}

export function useExperiment<T>(
  subjectId: string,
  experimentFlag: string,
  fallback?: string | T,
  options?: UseQueryRequestOptions<ExperimentValue<T>, unknown, true>
) {
  const response = useQueryRequest<ExperimentValue<T>, unknown, true>(
    ['useExperiment', subjectId, experimentFlag],
    () => getExperimentFlags<T>(subjectId, experimentFlag, fallback),
    { ...options }
  )

  return { isFeatureFlagEnabled: Boolean(response.data?.value), ...response }
}
