// eslint-disable @nx/enforce-module-boundaries
import { Endpoints } from '../endpoints'
import { httpClient } from '../http-client'
import { handleErrorResponse } from '../utils'

export namespace InvoiceConfiguration {
  export type Request = {
    certificate?: CertificateRequest
    csc?: Csc
    numberingControl?: NumberingControl
  }

  export type CertificateRequest = {
    fileName: string
    base64File: string | ArrayBuffer
    password: string
  }

  export type Item = {
    certificate: Certificate
    csc: Csc
    numberingControl: NumberingControl
  }

  export type Certificate = {
    isConfigured: boolean
    fileName: string
    validUntil: string
    isExpired: boolean
  }

  export type Csc = {
    isConfigured?: boolean
    id: number
    token: string
  }

  export type NumberingControl = {
    isConfigured?: boolean
    series?: number
    number?: number
  }

  export const getByStoreId = async (id: string): Promise<Item> => {
    try {
      const response = await httpClient.get<Item>(Endpoints.invoiceConfiguration.getByStoreId(id))
      return response.data
    } catch (error: unknown) {
      return handleErrorResponse(error)
    }
  }

  export const update = async (id: string, payload: Request): Promise<Item> => {
    try {
      const response = await httpClient.patch<Item>(Endpoints.invoiceConfiguration.update(id), payload)
      return response.data
    } catch (error: unknown) {
      return handleErrorResponse(error)
    }
  }
}
