import { type ExperimentValues } from '@stone-payments/experiment/src/lib/types'
import { isSwhExperimentOn } from 'swh/shared/utils'

import { type Entity } from '~/domains/platform/infra/deus-ex-machina/types'

export async function swhMenu(entity: Entity<'acquirer'>, expData: ExperimentValues | undefined) {
  if (expData === undefined || entity === undefined) return []

  const shouldShow = isSwhExperimentOn(expData, entity?.document)

  return [
    {
      order: 7,
      label: 'Vendas',
      icon: 'shop-outline',
      show: shouldShow,
      onClick: () => {},
      path: '/swh/vendas',
      active: (route: string) => ['swh/sales', '/swh/vendas'].includes(route)
    },
    {
      order: 8,
      label: 'Produtos',
      icon: 'tag-outline',
      show: shouldShow,
      onClick: () => {},
      path: '/catalogo/produtos',
      active: (route: string) => ['/swh/products', '/catalogo/produtos'].includes(route),
      isNew: true
    },
    {
      order: 9,
      label: 'Notas fiscais',
      icon: 'file-unfolded-outline',
      show: shouldShow,
      onClick: () => {},
      path: '/swh/notas-fiscais',
      active: (route: string) => ['swh/invoices', '/swh/notas-fiscais'].includes(route),
      isNew: true
    }
  ]
}
