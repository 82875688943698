import { type FlagProps } from '~/scaffold/mocks/types/mock-flags-types'

import { loanRenegociationGrace } from './loan-renegociation-grace.mock'

const creditHomeFlags: FlagProps[] = [
  {
    name: 'loan-renegociation-grace',
    groupName: 'Alavanca de Carência',
    scenarios: ['Renegociação em processo', 'Renegociado com parcelas variáveis'],
    initialScenario: 'Renegociação em processo',
    handlers: loanRenegociationGrace
  }
]

export { creditHomeFlags }
