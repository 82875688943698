// eslint-disable-next-line @nx/enforce-module-boundaries
import { type InvoiceConfiguration, type Stores } from 'swh/shared/http'

const checkConfigurations = (
  store?: Stores.Item,
  configuration?: InvoiceConfiguration.Item,
  checkAll: boolean = true
): boolean => {
  if (!store || !configuration) return false

  const { certificate, csc, numberingControl } = configuration || {}
  const { stateRegistration, cnae } = store || {}

  const configs = [
    certificate?.isConfigured,
    csc?.isConfigured,
    numberingControl?.isConfigured,
    !!stateRegistration,
    !!cnae
  ]

  return checkAll ? configs.every(config => config === true) : configs.some(config => config === true)
}

export const isInvoiceConfigured = (store?: Stores.Item, configuration?: InvoiceConfiguration.Item): boolean => {
  return checkConfigurations(store, configuration, true)
}

export const isSomeConfigured = (store?: Stores.Item, configuration?: InvoiceConfiguration.Item): boolean => {
  return checkConfigurations(store, configuration, false)
}
