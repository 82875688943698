import { useState } from 'react'
import { useRouter } from 'next/router'
import { type AxiosError } from 'axios'
// eslint-disable-next-line @nx/enforce-module-boundaries
import { type ErrorProperties, UpsertMode, useCategoriesOptions, useSwhConfig } from 'swh/shared/components'
import { ProductCatalog } from 'swh/shared/http'
// eslint-disable-next-line @nx/enforce-module-boundaries
import { setPath } from 'swh/shared/utils'

import { Actions } from './actions'
import { UpsertProduct } from './upsert-product'

const initialState = {
  categoryId: '',
  description: '',
  gtin: false,
  hasVariants: false,
  name: '',
  sku: false,
  subCategoryId: '',
  variants: [Actions.createVariant()]
}

const subCategoriesNoop: any[] = []

export const NewProduct = () => {
  const { store } = useSwhConfig()
  const [loading, setLoading] = useState(false)
  const router = useRouter()
  const [apiErrors, setApiErrors] = useState<ErrorProperties>(null)

  const onSubmit = async (_: any, product: Actions.PostProduct) => {
    if (!store?.id) return
    setLoading(true)
    try {
      const parsed = Actions.parse(product)
      await ProductCatalog.create(store.id, parsed)
      await router.push('/catalogo/produtos?product_saved=success')
    } catch (e) {
      if ((e as AxiosError).isAxiosError) {
        const error = e as AxiosError
        if (error.response?.status === 400) {
          const body = error.response?.data
          const messages = body?.errors ?? []
          if (messages.length === 0) return
          const apiErrors = messages.reduce((acc: any, el: { location: string; message: string }) => {
            return setPath(acc, el.location, el.message)
          }, {})
          setApiErrors(apiErrors)
        }
      }
    }
    setLoading(false)
  }

  const categories = useCategoriesOptions(store?.id, undefined)

  return (
    <UpsertProduct
      errors={apiErrors}
      product={null}
      subCategories={subCategoriesNoop}
      categories={categories}
      apiErrors={apiErrors}
      mode={UpsertMode.new}
      state={initialState}
      onSubmit={onSubmit}
      loading={loading}
    />
  )
}
