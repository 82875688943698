import { dynamicTemplate } from '~/lib/helpers/utils/dynamic-template'

import { httpClientCustomer } from '../http-client-customer'

interface FetchZipCodeInfoResponse {
  data: {
    city: string
    complement: string
    neighborhood: string
    postalCode: string
    state: string
    street: string
  }
}

export async function fetchZipCodeInfo(postalCode: string) {
  const url = dynamicTemplate('/postal-code/${postalCode}', { postalCode })

  return await httpClientCustomer<FetchZipCodeInfoResponse>({ url, method: 'GET' })
}
