import { dynamicTemplate } from '~/lib/helpers/utils/dynamic-template'
import { type Address } from '~/lib/types'

import { httpClientCustomer } from '..'

export interface GetAddressDataResponse {
  data: Array<Address>
}

export async function getAddressData({ document }: { document: string }) {
  const url = dynamicTemplate('/organizations/${document}/addresses', { document })

  return await httpClientCustomer<GetAddressDataResponse>({ url })
}
