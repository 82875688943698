import { type ComponentProps, type PropsWithChildren } from 'react'
import NextLink, { type LinkProps } from 'next/link'
import { useRouter } from 'next/router'
import { Grid, Stack } from '@dlpco/fluid-layout'
import { type IconId, Badge, Description, Heading, Icon, IconBadge, Text, Tooltip } from '@dlpco/ginga-stone'

import { PortsRevolvingCreditActionMainMenu } from '~/domains/credit/ports/components/revolving-credit-action-main-menu'
import { Box, Flex } from '~/domains/platform/design-system'
import { Skeleton } from '~/domains/platform/design-system/skeleton/skeleton'
import { Choose, For, If } from '~/domains/platform/lib/utilities-components'
import { stringFormat } from '~/lib/helpers/utils/string-format'
import { BalanceCard } from '~/ui/business-components/banking/balance-card/balance-card'
import { useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'
import { useViewSize } from '~/ui/hooks/utils/ui/use-view-size'

import { Heimdall } from '../../core/heimdall'
import { type Entity, type Subject } from '../../infra/deus-ex-machina/ports'
import { type MenuItem } from '../../layout/shared/entities'
import { NavLink, NavMenu } from '../../layout/shared/styles'
import { analytics } from '../../lib/analytics'
import { HiddenSensitiveToggle } from '../../lib/hidden-sensitive/components/sensitive-toggle'
import { useHiddenSensitive } from '../../lib/hidden-sensitive/hooks/use-hidden-sensitive'

import { menuConfig } from './main-menu-config'

import {
  ActionWrapper,
  SpecialButton,
  StyledBox,
  StyledTextButton,
  StyledTitleBox,
  WrapperSpecialButton
} from './main-menu.styles'

export interface MainMenuProps {
  minimal?: boolean
  entity: Entity<'acquirer' | 'banking'>
  callback: () => void

  subject: Subject<'stone_account_resources'>
}

export function MainMenu({ entity, callback, subject }: MainMenuProps) {
  const { route } = useRouter()
  const [isHiddenSensitive] = useHiddenSensitive()

  const nav = useQueryRequest<MenuItem[], unknown, true>(['main-menu-nav', entity.document], () =>
    menuConfig.nav(entity, subject.id)
  )

  return (
    <Box data-cy="menu">
      <StyledTextButton
        data-cy="switch-account"
        onClick={() => {
          callback()
          if (entity.roles.banking) {
            analytics.sendEvent('home web - button sidebar hidden')
          }
        }}
      >
        <Flex alignItems="flex-start" justifyContent="space-between">
          <StyledTitleBox>
            <Heading size="small" weight="semi">
              {entity.displayName}
            </Heading>
            <Box mt="0.125rem">
              <Description color="neutral" aria-label="documento">
                {stringFormat.document(entity.document)}
              </Description>
            </Box>
          </StyledTitleBox>
          <Box>
            <Icon use="chevron-down-outline" color="neutral" />
          </Box>
        </Flex>
      </StyledTextButton>

      <Box position="relative" data-cy="account-menu">
        <If condition={entity.roles.banking}>
          <Flex
            justifyContent="end"
            position={entity.roles.banking ? 'absolute' : 'relative'}
            right={0}
            top={0}
            m="0.75rem"
            zIndex={1}
          >
            <HiddenSensitiveToggle
              onClick={() => {
                analytics.sendEvent('home web - account balance clicked', {
                  content: !isHiddenSensitive ? 'hidden' : 'visible'
                })
              }}
            />
          </Flex>

          <Actions entity={entity} showBalance={!isHiddenSensitive} />
        </If>
      </Box>
      <StyledBox>
        <NavMenu data-cy="nav-menu">
          <Choose>
            <Choose.When
              condition={nav.isLoading}
              render={() => (
                <Stack space="1rem">
                  {' '}
                  <Skeleton height="2.3rem" width="100%" />
                  <Skeleton height="2.3rem" width="100%" />
                  <Skeleton height="2.3rem" width="100%" />
                  <Skeleton height="2.3rem" width="100%" />
                  <Skeleton height="2.3rem" width="100%" />
                  <Skeleton height="2.3rem" width="100%" />
                </Stack>
              )}
            />
            <Choose.When
              condition={Boolean(nav?.data)}
              render={() => (
                <For
                  of={nav?.data as MenuItem[]}
                  render={(item, index) => {
                    const { label, icon, show = true, onClick, path, active, isLocked, isNew } = item
                    return (
                      <If condition={show} key={index}>
                        <MaybeLink isLink={!(isLocked && isLocked())} href={{ pathname: path }}>
                          <NavLink
                            data-cy="nav-link"
                            disabled={Boolean(isLocked && isLocked())}
                            current={Boolean(active && active(route))}
                            onClick={onClick}
                            style={{ justifyContent: 'space-between' }}
                          >
                            <Flex gap="1rem">
                              <Icon use={icon as IconId} color="neutral" />
                              <Text inheritColor weight="semi">
                                {label}
                              </Text>
                            </Flex>
                            <If condition={Boolean(isLocked && isLocked())}>
                              <IconBadge color="info" icon="lock-close-outline" />
                            </If>
                            <If condition={Boolean(isNew)}>
                              <Badge color="warning" variant="solid">
                                Novo!
                              </Badge>
                            </If>
                          </NavLink>
                        </MaybeLink>
                      </If>
                    )
                  }}
                />
              )}
            />
          </Choose>
        </NavMenu>
      </StyledBox>
    </Box>
  )
}

export function Actions({ entity, showBalance }: { entity: Entity<'banking'>; showBalance: boolean }) {
  const { data, isLoading } = useQueryRequest<MenuItem[], unknown, true>([entity.document], () =>
    menuConfig.highlights(entity)
  )

  const hasRevolvingCredit = Heimdall.pass(['credit_revolving_credit_web', 'credit_beta_web'])

  const hasInvestments = Heimdall.pass(
    [
      'investment_daily_pf',
      'investment_daily_pj',
      'investment_monthly_pf',
      'investment_monthly_pj',
      'investment_scfi_v1_pf',
      'investment_scfi_v1_pj'
    ],
    'some'
  )

  return (
    <ActionWrapper>
      <BalanceCard
        accountId={entity.paymentAccount.id}
        showInvestmentBalance={hasInvestments}
        showBalance={showBalance}
      />
      <If condition={Boolean(hasRevolvingCredit)}>
        <PortsRevolvingCreditActionMainMenu
          organizationId={entity.id}
          document={entity.document}
          showValue={showBalance}
        />
      </If>
      <Box padding="1.25rem 0 0 0">
        <Choose>
          <Choose.When condition={isLoading} render={Loader} />
          <Choose.When condition={Boolean(data)} render={() => <Highlights highlights={data as MenuItem[]} />} />
        </Choose>
      </Box>
    </ActionWrapper>
  )
}

function Loader() {
  return (
    <Grid min="3ch" gutter="1.5rem">
      <Skeleton height="3.5rem" width="3.5rem" />
      <Skeleton height="3.5rem" width="3.5rem" />
      <Skeleton height="3.5rem" width="3.5rem" />
    </Grid>
  )
}

function Highlights({ highlights }: { highlights: MenuItem[] }) {
  return (
    <Grid min="3ch" gutter="1.5rem">
      <For
        of={highlights}
        render={(item, index) => {
          const { label, icon, show = true, onClick, isLocked } = item

          return (
            <If condition={show} key={index}>
              <MaybeTooltip
                tooltip={Boolean(isLocked && isLocked())}
                content="Faltam algumas informações para você usar todos os serviços."
                index={index}
              >
                <WrapperSpecialButton
                  alignItems="center"
                  flexDirection="column"
                  disabled={Boolean(isLocked && isLocked())}
                >
                  <SpecialButton key={index} disabled={Boolean(isLocked && isLocked())} onClick={onClick}>
                    <Icon use={icon as IconId} />
                    <If condition={Boolean(isLocked && isLocked())}>
                      <Box position="absolute" right="0" top="0">
                        <IconBadge color="info" icon="lock-close-outline" />
                      </Box>
                    </If>
                  </SpecialButton>

                  <Description>{label}</Description>
                </WrapperSpecialButton>
              </MaybeTooltip>
            </If>
          )
        }}
      />
    </Grid>
  )
}

function MaybeTooltip({
  tooltip,
  index,
  children,
  ...tooltipProps
}: PropsWithChildren<{ tooltip?: boolean; index?: number } & ComponentProps<typeof Tooltip>>) {
  const { isViewMedium } = useViewSize()

  if (tooltip && !isViewMedium)
    return (
      <Box zIndex={index === 0 ? 10 : 5}>
        <Tooltip placement="left" {...tooltipProps}>
          {children}
        </Tooltip>
      </Box>
    )

  return <>{children}</>
}

function MaybeLink({
  children,
  isLink,
  ...linkProps
}: PropsWithChildren<{ isLink: boolean } & LinkProps>): JSX.Element {
  const { isViewMedium } = useViewSize()

  if (isLink)
    return (
      <NextLink {...linkProps} legacyBehavior passHref>
        {children}
      </NextLink>
    )

  return (
    <Choose>
      <Choose.When condition={!isViewMedium}>
        <Tooltip placement="left" content="Faltam algumas informações para você usar todos os serviços.">
          {children}
        </Tooltip>
      </Choose.When>
      <Choose.Otherwise>{children}</Choose.Otherwise>
    </Choose>
  )
}
