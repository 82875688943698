export const SEND_STICKER = 'ENVIO DE ADESIVOS'
export const SEND_PAPER_ROLL = 'ENVIO DE BOBINA'
export const SEND_PAPER_ROLES = 'ENVIO DE BOBINAS'
export const DELIVERY_PAPER_ROLL = 'ENTREGA DE BOBINA'
export const PINPAD_UNINSTALL = 'DESINSTALAÇÃO PINPAD'
export const PINPAD_INSTALL = 'INSTALAÇÃO PINPAD'
export const CHIP_CHANGE = 'TROCA DE CHIP'
export const DIRECT_REPLACEMENT_CHIP = 'TROCA DE CHIP DIRETA'
export const PINPAD_REPLACEMENT = 'TROCA PINPAD'
export const DIRECT_REPLACEMENT = 'TROCA DIRETA'

export const REPLACEMENT = 'TROCA'
export const INSTALL = 'INSTALAÇÃO'
export const SUPPLIES = 'ENVIO DE SUPRIMENTOS'
export const UNINSTALL = 'DESINSTALAÇÃO'
export const MAINTENANCE = 'MANUTENÇÃO'

export const NEW = 'NOVA'
export const RECEIVED = 'RECEBIDA'
export const IN_TREATMENT = 'EM TRATAMENTO'
export const TREATMENT_N1 = 'EM TRATAMENTO N1'
export const TREATMENT_N2 = 'EM TRATAMENTO N2'
export const REPIQUE = 'REPIQUE'
export const IN_TRANSFER = 'EM TRANSFERENCIA'
export const CANCELED = 'CANCELADA'
export const BAIXADA = 'BAIXADA'
export const IN_FIELD = 'EM CAMPO'
export const PRE_BAIXADA = 'PRÉ-BAIXADA'

export const PAPER_ROLL = 'Bobina'
export const TECHNICAL_VISIT = 'Visita técnica'
export const TERMINAL = 'Maquininha'
export const STICKER = 'Adesivo'
export const SERVICE_UNINSTALL = 'Desinstalação'
