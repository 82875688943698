import { type FC, type ReactNode } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { type FlexDirectionProperty } from 'csstype'

import { Money } from '~/ui/components/fields/money'

import { logger } from '../../infra/monitoring/logger'
import { Choose, If } from '../../lib/utilities-components'
import { Flex, Text } from '../index'
import { type BoxProps } from '../types'

import { Image, Label, Value } from './styles'

let idCounter = 0

const uniqueFieldId = (name: string): string => {
  const id = ++idCounter
  return `${name}${id}`
}

export interface FieldProps extends BoxProps {
  value?: any
  label?: string | ReactNode
  variation?: 'text' | 'image' | 'money'
  render?: FC<React.PropsWithChildren<unknown>>
  direction?: FlexDirectionProperty
  children?: (value?: any, renderProps?: Record<string, any>) => ReactNode
  formatter?: (value: any) => any
  renderProps?: Record<string, any>
  leased?: boolean
  alt?: string
  imageWidth?: string
  labelColor?: string
  fieldColor?: string
  justifyContent?: string
  p?: string
  px?: string
  py?: string
  padding?: string
  pretty?: boolean
  fontSize?: string
}

export const Field = (props: FieldProps) => {
  const {
    value,
    label,
    variation,
    renderProps = {},
    direction = 'column',
    children,
    formatter = x => x,
    leased,
    alt,
    imageWidth,
    labelColor,
    fieldColor,
    justifyContent,
    pretty,
    fontSize,
    ...rest
  } = props

  const transformedValue = formatter(value)

  const id = uniqueFieldId(`field`)

  const labelString = typeof label === 'string' ? label : undefined

  return (
    <If condition={Boolean(value) || Number.isFinite(value)}>
      <Flex flexDirection={direction} justifyContent={justifyContent} {...rest}>
        <If condition={Boolean(label)}>
          <Label data-testid="label" color={labelColor} id={id} as="label" {...renderProps}>
            {label}
          </Label>
        </If>
        <ErrorBoundary
          FallbackComponent={() => <Text>Oops, algo aconteceu, mas não é culpa sua.</Text>}
          onError={error => {
            logger(error)
          }}
        >
          {children ? (
            children(transformedValue, renderProps)
          ) : (
            <Choose>
              <Choose.When condition={variation === 'text'}>
                <Text color={fieldColor} aria-labelledby={id} aria-label={labelString} fontWeight="body">
                  {transformedValue}
                </Text>
              </Choose.When>
              <Choose.When condition={variation === 'image'}>
                <Image
                  src={transformedValue || 'https://stgstorefront.mundipagg.com/assets/no-picture.a2def2f6.svg'}
                  alt={alt}
                  aria-labelledby={id}
                  aria-label={labelString}
                  leased={leased}
                  imageWidth={imageWidth}
                />
              </Choose.When>
              <Choose.When condition={variation === 'money'}>
                <Money
                  aria-labelledby={id}
                  aria-label={labelString}
                  pretty={pretty}
                  value={transformedValue}
                  renderProps={{ ...renderProps }}
                />
              </Choose.When>
              <Choose.Otherwise>
                <Value
                  color={fieldColor}
                  fontSize={fontSize}
                  data-testid="default-render"
                  aria-labelledby={id}
                  aria-label={labelString}
                  {...renderProps}
                >
                  {transformedValue}
                </Value>
              </Choose.Otherwise>
            </Choose>
          )}
        </ErrorBoundary>
      </Flex>
    </If>
  )
}

Field.defaultProps = {
  leased: false
}
