import { type InstalledPlugins } from '../index'
import { type TMInstanceWithPlugins, type TmPlugin } from '../types'

export interface RangeTmMethods {
  getLast7Days(): TmRange
  getYesterday(): TmRange
  getLast15Days(): TmRange
  getLastMonth(): TmRange
  getLastYear(): TmRange
  getLast30Days(): TmRange
  getLast3Months(): TmRange
  getLast6Months(): TmRange
  getLast12Months(): TmRange
  getLast18Months(): TmRange
  getLast2Years(): TmRange
  getFirstDayMonth(): TmRange
  getLastDayMonth(): TmRange
  getThisMonth(): TmRange
}

type TmRange = [TMInstanceWithPlugins<InstalledPlugins>, TMInstanceWithPlugins<InstalledPlugins>]

export const rangeTMPlugin: TmPlugin<RangeTmMethods> = (instance, timeMachine) => {
  const proto = instance.prototype

  proto.getLast7Days = function () {
    const today = this.clone()

    return [timeMachine(today).subtractDay(6).startOfDay(), timeMachine(today).endOfDay()]
  }

  proto.getYesterday = function () {
    const today = this.clone()

    return [timeMachine(today).subtractDay(1).startOfDay(), timeMachine(today).subtractDay(1).endOfDay()]
  }

  proto.getLast15Days = function () {
    const today = this.clone()

    return [timeMachine(today).subtractDay(14).startOfDay(), timeMachine(today).endOfDay()]
  }

  proto.getLastMonth = function () {
    const today = this.clone()

    return [
      timeMachine(today).subtractMonth(1).startOfMonth().startOfDay(),
      timeMachine(today).subtractMonth(1).endOfMonth().endOfDay()
    ]
  }

  proto.getLastYear = function () {
    const today = this.clone()

    return [timeMachine(today).subtractYear(1).startOfDay(), timeMachine(today).subtractYear(1).endOfDay()]
  }

  proto.getLast30Days = function () {
    return [timeMachine().subtractDay(29).startOfDay(), timeMachine().endOfDay()]
  }

  proto.getLast3Months = function () {
    return [timeMachine().subtractMonth(3).startOfDay(), timeMachine().endOfDay()]
  }

  proto.getLast6Months = function () {
    return [timeMachine().subtractMonth(6).startOfDay(), timeMachine().endOfDay()]
  }

  proto.getLast12Months = function () {
    return [timeMachine().subtractMonth(12).startOfDay(), timeMachine().endOfDay()]
  }

  proto.getLast18Months = function () {
    return [timeMachine().subtractMonth(18).startOfDay(), timeMachine().endOfDay()]
  }

  proto.getLast2Years = function () {
    return [timeMachine().subtractYear(2).startOfDay(), timeMachine().endOfDay()]
  }

  proto.getFirstDayMonth = function () {
    const today = this.clone()

    return [timeMachine(today).startOfMonth().startOfDay(), timeMachine(today).startOfMonth().endOfDay()]
  }

  proto.getLastDayMonth = function () {
    const today = this.clone()

    return [timeMachine(today).endOfMonth().startOfDay(), timeMachine(today).endOfMonth().endOfDay()]
  }

  proto.getThisMonth = function () {
    const today = this.clone()

    return [timeMachine(today).startOfMonth().startOfDay(), timeMachine(today).endOfMonth().endOfDay()]
  }
}
