import ReactGA from 'react-ga'
import Router from 'next/router'
import { PUBLIC_GOOGLE_ANALYTICS } from 'shared/envs'

import { logger } from '~/domains/platform/infra/monitoring/logger'

import { UNSAFE_trackHotjarEvent } from './hotjar'
import { type AnalyticsProps } from './interface'

interface UserInfo {
  userId?: string
  appVersion: string
}

type AvailableTrackServices = 'analytics' | 'recording'
export interface EventsProps {
  trackServices: Array<AvailableTrackServices>
  action: string
  category: string
  label?: string
  value?: number
}

export function handleUserInfoHJ(userInfo: UserInfo) {
  window?.hj('identify', userInfo.userId, {
    userId: userInfo.userId,
    appVersion: userInfo.appVersion
  })
}

function initGA(userInfo: UserInfo) {
  if (!PUBLIC_GOOGLE_ANALYTICS()) return

  ReactGA.initialize(PUBLIC_GOOGLE_ANALYTICS(), { gaOptions: { userId: userInfo.userId } })
}

function logPageView() {
  ReactGA.set({ page: window.location.pathname })
  ReactGA.pageview(window.location.pathname)
}

function analyticsWithPageViewLog(userInfo: UserInfo) {
  initGA(userInfo)
  handleUserInfoHJ(userInfo)
  logPageView()
  Router.events.on('routeChangeComplete', logPageView)
}

export function initializeAnalytics(userInfo: UserInfo) {
  try {
    analyticsWithPageViewLog(userInfo)
  } catch (error) {
    logger(`An error was ocurred when try initialize Initialize some of Analytcs tools. ${error} `)
  }
}

/**
 * @deprecated The method uses google analytics so it shouldn't be used, you should use the new amplitude provider.
 * @see [amplitude](https://github.com/dlpco/dashboard/wiki/SDK-Web-Analytics)
 */
export const trackAnalyticsEvent = ({ action, category, label, value }: AnalyticsProps) => {
  ReactGA.event({ action, category, label, value })
}

const tracks = {
  ['analytics']: (action: string, category: string, label?: string, value?: number) =>
    trackAnalyticsEvent({ action, category, label, value }),
  ['recording']: (action: string) => UNSAFE_trackHotjarEvent(action)
}

/**
 * @deprecated The method uses google analytics so it shouldn't be used, you should use the new amplitude provider.
 * @see [amplitude](https://github.com/dlpco/dashboard/wiki/SDK-Web-Analytics)
 */
export const trackEvents = ({ trackServices, action, category, label, value }: EventsProps) => {
  trackServices.forEach(track => tracks[track](action, category, label, value))
}
